<!-- src/components/SignUp.vue -->

<template>
  <v-dialog v-model="dialog" max-width="448px">
    <div>
      <v-card class="mx-auto pa-12 pb-8" elevation="8" max-width="448" rounded="lg">
        <div class="text-subtitle-1 text-medium-emphasis">Create Account</div>

        <v-text-field
          density="compact"
          placeholder="Email address"
          prepend-inner-icon="mdi-email-outline"
          variant="outlined"
          color="primary"
          v-model="email"
          required
        ></v-text-field>

        <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
          Password
        </div>

        <v-text-field
          :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
          :type="visible ? 'text' : 'password'"
          density="compact"
          placeholder="Enter your password"
          prepend-inner-icon="mdi-lock-outline"
          variant="outlined"
          color="primary"
          v-model="password"
          required
          @click:append-inner="visible = !visible"
        ></v-text-field>

        <v-btn block class="mb-8" color="primary" size="large" variant="tonal" @click="signUp">
          Sign Up
        </v-btn>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import { getFirestore, doc, setDoc } from "firebase/firestore";
import AuthService from "../services/AuthService";

export default {
  name: "SignUp",
  data() {
    return {
      dialog: false,
      email: "",
      password: "",
      visible: false,
    };
  },
  methods: {
    async signUp() {
      try {
        const userCredential = await AuthService.register(this.email, this.password);
        const { user } = userCredential;

        const db = getFirestore();
        const userDocRef = doc(db, "users", user.uid);
        await setDoc(userDocRef, {
          email: this.email,
        });

        this.dialog = false;
        this.$emit("showEmailVerification");
      } catch (error) {
        this.$emit("signUpError", error.message);
      }
    },
  },
};
</script>
