<!-- src/components/SignIn.vue -->

<template>
  <v-dialog v-model="dialog" max-width="448px">
    <div>
      <v-card class="mx-auto pa-12 pb-8" elevation="8" max-width="448" rounded="lg">
        <div class="text-subtitle-1 text-medium-emphasis">Account</div>

        <v-text-field
          density="compact"
          placeholder="Email address"
          prepend-inner-icon="mdi-email-outline"
          variant="outlined"
          color="primary"
          v-model="email"
          required
        ></v-text-field>

        <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
          Password
          <a
            class="text-caption text-decoration-none text-primary"
            href="#"
            @click.prevent="handleForgotPassword"
          >
            Forgot login password?
          </a>
        </div>

        <v-text-field
          :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
          :type="visible ? 'text' : 'password'"
          density="compact"
          placeholder="Enter your password"
          prepend-inner-icon="mdi-lock-outline"
          variant="outlined"
          color="primary"
          v-model="password"
          required
          @click:append-inner="visible = !visible"
        ></v-text-field>

        <v-btn block class="mb-8" color="primary" size="large" variant="tonal" @click="signIn">
          Log In
        </v-btn>

        <v-card-text class="text-center">
          <a
            class="text-primary text-decoration-none"
            href="#"
            rel="noopener noreferrer"
            target="_blank"
            @click.prevent="showSignUp"
          >
            Sign up now <v-icon icon="mdi-chevron-right"></v-icon>
          </a>
        </v-card-text>
      </v-card>
    </div>
  </v-dialog>
  <v-dialog v-model="forgotPasswordDialog" width="500">
    <v-card>
      <v-card-title class="custom-title">Reset Password</v-card-title>
      <v-card-text>
        <v-text-field
          label="Enter your email address"
          v-model="forgotPasswordEmail"
          variant="outlined"
          color="primary"
          type="email"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn variant="text" color="primary" @click="sendResetEmail">Send</v-btn>
        <v-btn variant="text" color="secondary" @click="forgotPasswordDialog = false">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <AuthSnackbar
    :visible="snackbarVisible"
    :message="snackbarMessage"
    :color="snackbarColor"
    @update:visible="snackbarVisible = $event"
  />
</template>

<script>
import AuthService from "../services/AuthService";
import AuthSnackbar from "./AuthSnackbar.vue";

export default {
  name: "SignIn",
  emits: ["showSignUp", "signInError"],
  components: {
    AuthSnackbar,
  },
  data() {
    return {
      dialog: false,
      email: "",
      password: "",
      visible: false,
      forgotPasswordDialog: false,
      forgotPasswordEmail: "",
      snackbarVisible: false,
      snackbarMessage: "",
      snackbarColor: "error",
    };
  },
  methods: {
    signIn() {
      AuthService.login(this.email, this.password)
        .then(() => {
          // Signed in
          this.dialog = false;
        })
        .catch((error) => {
          this.$emit("signInError", error.message);
        });
    },
    showSignUp() {
      this.dialog = false;
      this.$emit("showSignUp");
    },
    handleForgotPassword(event) {
      event.stopPropagation();
      this.forgotPasswordDialog = true;
    },
    async sendResetEmail() {
      try {
        await AuthService.sendPasswordResetEmail(this.forgotPasswordEmail);
        this.snackbarMessage = "Password reset email sent! Check your inbox.";
        this.snackbarColor = "secondary";
        this.snackbarVisible = true;
        this.forgotPasswordDialog = false;
        this.forgotPasswordEmail = "";
      } catch (error) {
        this.snackbarMessage = "Failed to send password reset email. Please try again.";
        this.snackbarColor = "error";
        this.snackbarVisible = true;
      }
    },
  },
};
</script>

<style scoped>
.custom-title {
  font-weight: bold;
  color: #0070ba;
}
</style>
