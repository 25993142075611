<!-- src/components/ImageOverlay.vue -->

<template>
  <v-dialog v-model="carouselDialog" fullscreen>
    <v-btn
      icon
      size="medium"
      variant="outlined"
      color="white"
      class="carousel-close-btn"
      @click="closeCarousel"
    >
      <v-icon color="white">mdi-close</v-icon>
    </v-btn>

    <v-carousel
      v-model="currentImageIndex"
      class="carousel-section"
      hide-delimiter-background
      delimiter-icon="mdi-square"
      height="70vh"
      color="white"
    >
      <!-- Custom Previous Button -->
      <template v-slot:prev="{ props }">
        <v-btn
          icon
          size="large"
          class="carousel-prev-btn"
          color="white"
          variant="tonal"
          @click="props.onClick"
        >
          <v-icon size="40">mdi-chevron-left</v-icon>
        </v-btn>
      </template>

      <!-- Custom Next Button -->
      <template v-slot:next="{ props }">
        <v-btn
          icon
          size="large"
          class="carousel-next-btn"
          color="white"
          variant="tonal"
          @click="props.onClick"
        >
          <v-icon size="40">mdi-chevron-right</v-icon>
        </v-btn>
      </template>

      <v-carousel-item v-for="(image, index) in images" :key="index" cover class="carousel-item">
        <v-img :src="image" :alt="`Image ${index + 1}`"></v-img>
      </v-carousel-item>
    </v-carousel>
  </v-dialog>
</template>

<script>
export default {
  name: "ImageOverlay",
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    carouselVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      carouselDialog: this.carouselVisible,
      currentImageIndex: 0,
    };
  },
  watch: {
    carouselVisible: "syncDialogVisibility",
  },
  methods: {
    setCurrentImageIndex(index) {
      this.currentImageIndex = index;
    },
    closeCarousel() {
      this.carouselDialog = false;
      this.$emit("update:carouselVisible", this.carouselDialog);
    },
    syncDialogVisibility() {
      this.carouselDialog = this.carouselVisible;
      this.$emit("update:carouselVisible", this.carouselDialog);
    },
  },
};
</script>

<style scoped>
.v-carousel {
  flex-grow: 1;
}

.carousel-section {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.carousel-item {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.v-responsive {
  position: static !important; /* Overrides relative positioning */
}

.carousel-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
</style>
