<!-- src/components/ManufcaturerDropdown.vue -->

<template>
  <v-menu :close-on-content-click="false">
    <template v-slot:activator="{ props }">
      <v-btn color="primary" variant="outlined" rounded="xs" v-bind="props" class="ml-2 mr-2">
        Manufacturer
        <v-icon end>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list style="max-height: 300px; overflow-y: auto">
      <v-item-group
        v-if="manufacturerOptions.length"
        multiple
        v-model="selectedItems"
        @input="updateFilter"
      >
        <v-list-item v-for="(manufacturer, index) in manufacturerOptions" :key="index">
          <v-checkbox-btn
            color="primary"
            :label="manufacturer"
            v-model="selectedItems[index]"
          ></v-checkbox-btn>
        </v-list-item>
      </v-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import aircraftManufacturers from "../data/aircraftManufacturers";

export default {
  name: "ManufacturerDropdown",
  computed: {
    ...mapState(["filters"]),
  },
  watch: {
    filters: {
      deep: true,
      handler(newFilters) {
        // Reset selectedItems if the manufacturer filter is not in the store
        if (!newFilters.manufacturer) {
          this.selectedItems = [];
        }
      },
    },
  },
  data() {
    return {
      selectedItems: [],
      manufacturerOptions: aircraftManufacturers,
    };
  },
  methods: {
    ...mapMutations(["setFilter"]),
    updateFilter() {
      const selectManufacturers = this.manufacturerOptions.filter(
        (_, index) => this.selectedItems[index]
      );
      this.setFilter({ filterType: "manufacturer", value: selectManufacturers });
    },
  },
};
</script>
