<!-- src/components/PlaneFinderOverlay.vue -->

<template>
  <v-dialog v-model="dialog" class="dialog-class">
    <v-card class="overlay-card">
      
      <!-- Header text -->
      <v-card-title class="text-h5 title-text font-weight-bold d-flex justify-center pt-6">
        <strong>PlaneFinder AI</strong>
      </v-card-title>

      <v-card-subtitle class="ustify-center pb-4 subtitle-wrap">
        Find the airplane that is right for you. Input your important variables, then click "submit" to view results. 
      </v-card-subtitle>
      
      <v-card-text class="scrollable-card-text">
        <div v-if="isLoading" class="loading-container d-flex flex-column align-center justify-center flex-grow-1">

          <!-- Conditional rendering while API is processing -->
          <v-progress-circular indeterminate color="primary" :size="100" :width="8">
            <template v-slot:default>
              <v-icon>mdi-airplane</v-icon>
            </template>
          </v-progress-circular>
          <span class="loading-text">Finding the bird of your dreams...</span>
        </div>

        <!-- Conditional rendering once response is available -->
        <div v-else-if="apiResponse" class="response-container flex-grow-1">
          <div v-html="formattedApiResponse" class="api-response-style"></div>
        </div>

        <div v-else class="flex-grow-1">
          <!-- Tradeoffs & preferences title -->
          <v-row class="d-flex justify-center">
            <div class="custom-subheader-wrapper">
              <v-list-subheader class="custom-list-subheader">Preferences & Tradeoffs</v-list-subheader>
            </div>
          </v-row>
          
          <!-- Cost vs. luxury slider -->
          <v-row class="d-flex justify-center">
            <v-slider
              class="slider-class"
              color="primary"
              :max="4"
              show-ticks
              tick-size="5"
              step="1"
              v-model="costLuxurySlider"
            >
              <template v-slot:prepend>
                <v-card-text class="slider-text">Low-Cost</v-card-text>
              </template>
              <template v-slot:append>
                <v-card-text class="slider-text">Luxury</v-card-text>
              </template>
            </v-slider>
          </v-row>

          <!-- Recreation vs. business slider -->
          <v-row class="d-flex justify-center">
            <v-slider
              class="slider-class"
              color="primary"
              :max="4"
              show-ticks
              tick-size="5"
              step="1"
              v-model="recreationBusinessSlider"
            >
              <template v-slot:prepend>
                <v-card-text class="slider-text">Recreation</v-card-text>
              </template>
              <template v-slot:append>
                <v-card-text class="slider-text">Business</v-card-text>
              </template>
            </v-slider>
          </v-row>

          <!-- Short-hops vs. long-distance slider -->
          <v-row class="d-flex justify-center">
            <v-slider
              class="slider-class"
              color="primary"
              :max="4"
              show-ticks
              tick-size="5"
              step="1"
              v-model="shortLongDistanceSlider"
            >
              <template v-slot:prepend>
                <v-card-text class="slider-text">Short-Hops</v-card-text>
              </template>
              <template v-slot:append>
                <v-card-text class="slider-text">Cross-Country</v-card-text>
              </template>
            </v-slider>
          </v-row>

          <!-- Efficiency vs. performance slider -->
          <v-row class="d-flex justify-center">
            <v-slider
              class="slider-class"
              color="primary"
              :max="4"
              show-ticks
              tick-size="5"
              step="1"
              v-model="efficiencyPerformanceSlider"
            >
              <template v-slot:prepend>
                <v-card-text class="slider-text">Efficiency</v-card-text>
              </template>
              <template v-slot:append>
                <v-card-text class="slider-text">Performance</v-card-text>
              </template>
            </v-slider>
          </v-row>

          <!-- Performance & specifications title -->
          <v-row class="d-flex justify-center">
            <div class="custom-subheader-wrapper">
              <v-list-subheader class="custom-list-subheader">Performance & Specifications</v-list-subheader>
            </div>
          </v-row>

            <!-- Cost -->
          <v-row class="d-flex justify-center">
            <v-col class="px-12">
              <v-select
                label="Cost"
                :items="costOptions"
                v-model="selectedCost"
                variant="outlined"
                dense
                clearable
                color="primary"
              >
                <template v-slot:prepend>
                  <v-icon>mdi-cash</v-icon>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <!-- Range -->
          <v-row class="d-flex justify-center">
            <v-col class="px-12">
              <v-select
                label="Range"
                :items="rangeOptions"
                v-model="selectedRange"
                variant="outlined"
                dense
                clearable
                color="primary"
              >
                <template v-slot:prepend>
                  <v-icon>mdi-signal-distance-variant</v-icon>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <!-- Cruising speed -->
          <v-row class="d-flex justify-center">
            <v-col class="px-12">
              <v-select
                label="Cruising Speed"
                :items="cruiseSpeedOptions"
                v-model="selectedCruiseSpeed"
                variant="outlined"
                dense
                clearable
                color="primary"
              >
                <template v-slot:prepend>
                  <v-icon>mdi-speedometer</v-icon>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <!-- Service ceiling -->
          <v-row class="d-flex justify-center">
            <v-col class="px-12">
              <v-select
                label="Service Ceiling"
                :items="serviceCeilingOptions"
                v-model="selectedServiceCeiling"
                variant="outlined"
                dense
                clearable
                color="primary"
              >
                <template v-slot:prepend>
                  <v-icon>mdi-arrow-up-thick</v-icon>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <!-- Takeoff and landing distance -->
          <v-row class="d-flex justify-center">
            <v-col class="px-12">
              <v-select
                label="Takeoff and Landing Distance"
                :items="takeoffLandingDistanceOptions"
                v-model="selectedTakeoffLandingDistance"
                variant="outlined"
                dense
                clearable
                color="primary"
              >
                <template v-slot:prepend>
                  <v-icon>mdi-airplane-takeoff</v-icon>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <!-- Passenger capacity -->
          <v-row class="d-flex justify-center">
            <v-col class="px-12">
              <v-select
                label="Passenger Capacity"
                :items="passengerCapacityOptions"
                v-model="selectedPassengerCapacity"
                variant="outlined"
                dense
                clearable
                color="primary"
              >
                <template v-slot:prepend>
                  <v-icon>mdi-seat-passenger</v-icon>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <!-- Cargo capacity -->
          <v-row class="d-flex justify-center">
            <v-col class="px-12">
              <v-select
                label="Cargo Capacity"
                :items="cargoCapacityOptions"
                v-model="selectedCargoCapacity"
                variant="outlined"
                dense
                clearable
                color="primary"
              >
                <template v-slot:prepend>
                  <v-icon>mdi-bag-checked</v-icon>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <!-- Fuel Efficiency -->
          <v-row class="d-flex justify-center">
            <v-col class="px-12">
              <v-select
                label="Fuel Efficiency (Gallons Per Hour)"
                :items="fuelEfficiencyOptions"
                v-model="selectedFuelEfficiency"
                variant="outlined"
                dense
                clearable
                color="primary"
              >
                <template v-slot:prepend>
                  <v-icon>mdi-gas-station</v-icon>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <!-- Avionics/Technology -->
          <v-row class="d-flex justify-center">
            <v-col class="px-12">
              <v-select
                label="Avionics/Technology"
                :items="avionicsTechnologyOptions"
                v-model="selectedAvionicsTechnology"
                variant="outlined"
                dense
                clearable
                color="primary"
              >
                <template v-slot:prepend>
                  <v-icon>mdi-gauge</v-icon>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <!-- Weather capabilities -->
          <v-row class="d-flex justify-center">
            <v-col class="px-12">
              <v-select
                label="Weather Capabilities"
                :items="weatherCapabilitiesOptions"
                v-model="selectedWeatherCapabilities"
                variant="outlined"
                dense
                clearable
                color="primary"
              >
                <template v-slot:prepend>
                  <v-icon>mdi-weather-lightning-rainy</v-icon>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <!-- Other considerations title -->
          <v-row class="d-flex justify-center">
            <div class="custom-subheader-wrapper">
              <v-list-subheader class="custom-list-subheader">Other</v-list-subheader>
            </div>
          </v-row>

          <!-- Other user text inputs -->
          <v-row class="d-flex justify-center">
            <v-col class="px-12">
              <v-textarea
                label="Enter any other considerations..."
                v-model="otherUserConsiderations"
                variant="outlined"
                dense
                color="primary"
              ></v-textarea>
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-card-actions class="justify-end">
        <!-- Sticky Submit and Cancel buttons -->
        <v-row class="bottom-buttons">
          <v-spacer></v-spacer>
          <v-btn color="secondary" variant="tonal" class="ma-2" @click="handleCancel">Cancel</v-btn>
          <v-btn :color="buttonColor" variant="flat" class="ma-2" @click="handleButtonClick">
            {{ showRestartButton ? 'Restart' : 'Submit' }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-row>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import { httpsCallable } from 'firebase/functions';
import { functions } from '@/firebaseConfig.js';

export default {
  name: "PlaneFinderOverlay",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      apiResponse: null,
      costLuxurySlider: 2,
      recreationBusinessSlider: 2,
      shortLongDistanceSlider: 2,
      efficiencyPerformanceSlider: 2,
      selectedCost: null,
      costOptions: [
        'Under $100,000',
        '$100,000 to $300,000',
        '$300,000 to $500,000',
        '$500,000 to $1 million',
        'Over $1 million'
      ],
      selectedRange: null,
      rangeOptions: [
        'Under 300 miles',
        '300 to 600 miles',
        '600 to 1,000 miles',
        '1,000 to 1,500 miles',
        'Over 1,500 miles'
      ],
      selectedCruiseSpeed: null,
      cruiseSpeedOptions: [
        'Under 100 knots',
        '100 to 150 knots',
        '150 to 200 knots',
        '200 to 250 knots',
        'Over 250 knots'
      ],
      selectedServiceCeiling: null,
      serviceCeilingOptions: [
        'Under 8,000 feet',
        '8,000 to 12,000 feet',
        '12,000 to 16,000 feet',
        '16,000 to 20,000 feet',
        'Over 20,000 feet'
      ],
      selectedTakeoffLandingDistance: null,
      takeoffLandingDistanceOptions: [
        'Under 500 feet',
        '500 to 1,000 feet',
        '1,000 to 1,500 feet',
        '1,500 to 2,000 feet',
        'Over 2,000 feet'
      ],
      selectedPassengerCapacity: null,
      passengerCapacityOptions: [
        '1 (Pilot only)',
        '2-3',
        '4-5',
        '6-7',
        'Over 7'
      ],
      selectedCargoCapacity: null,
      cargoCapacityOptions: [
        'Under 200 lbs',
        '200 to 400 lbs',
        '400 to 600 lbs',
        '600 to 800 lbs',
        'Over 800 lbs'
      ],
      selectedFuelEfficiency: null,
      fuelEfficiencyOptions: [
        'Under 5 GPH',
        '5 to 10 GPH',
        '10 to 15 GPH',
        '15 to 20 GPH',
        'Over 20 GPH'
      ],
      selectedAvionicsTechnology: null,
      avionicsTechnologyOptions: [
        'Basic (VFR, Minimal Avionics)',
        'Intermediate (Standard IFR, GPS)',
        'Advanced (Glass cockpit, Autopilot)',
        'High-End (Enhanced navigation and safety features)',
        'Top-of-the-Line (Full digital integration, Synthetic vision)'
      ],
      selectedWeatherCapabilities: null,
      weatherCapabilitiesOptions: [
        'VFR Only',
        'Basic IFR',
        'Enhanced IFR (Advanced navigation aids)',
        'All-Weather Capable (Deicing, Weather radar)',
        'High-End Weather Systems (Integrated advanced weather radar, Storm avoidance systems)'
      ],
      otherUserConsiderations: '',
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    formattedApiResponse() {
      // First, replace Markdown-style bold text with HTML bold tags
      let responseWithBold = this.apiResponse.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

      // Then, replace newline characters with <br> tags
      return responseWithBold.replace(/\n/g, '<br>');
    },
    showRestartButton() {
      return this.apiResponse !== null;
    },
    buttonColor() {
      return this.showRestartButton ? 'success' : 'primary';
    },
  },
  methods: {
    handleCancel() {
      this.$emit('closePlaneFinderOverlay');
      this.costLuxurySlider = 2;
      this.recreationBusinessSlider = 2;
      this.shortLongDistanceSlider = 2;
      this.efficiencyPerformanceSlider = 2;
      this.selectedCost = null;
      this.selectedRange = null;
      this.selectedCruiseSpeed = null;
      this.selectedServiceCeiling = null;
      this.selectedTakeoffLandingDistance = null;
      this.selectedPassengerCapacity = null;
      this.selectedCargoCapacity = null;
      this.selectedFuelEfficiency = null;
      this.selectedAvionicsTechnology = null;
      this.selectedWeatherCapabilities = null;
      this.otherUserConsiderations = '';
    },
    handleButtonClick() {
      if (this.showRestartButton) {
        this.handleRestart();
      } else {
        this.handleSubmit();
      }
    },
    async handleSubmit() {
      this.isLoading = true;
      const userInputs = this.summarizeUserInputs();
      const callOpenAiApi = httpsCallable(functions, 'callOpenAiApi');

      try {
        const result = await callOpenAiApi({ userInputs });
        this.apiResponse = result.data.response;
        console.log('Complete response from Firebase function:', result);
        console.log('OpenAI API Response:', result.data.response);
      } catch (error) {
        console.error('Error submitting to OpenAI:', error);
        this.apiResponse = 'Error occurred while fetching data';
      } finally {
        this.isLoading = false;
      }
    },
    summarizeUserInputs() {
      return `User Preferences:\n` +
            `Cost and Luxury: ${this.costLuxurySlider}, ` +
            `Recreation vs Business: ${this.recreationBusinessSlider}, ` +
            `Short vs Long Distance: ${this.shortLongDistanceSlider}, ` +
            `Efficiency vs Performance: ${this.efficiencyPerformanceSlider}, ` +
            `Selected Cost: ${this.selectedCost || 'Not specified'}, ` +
            `Selected Range: ${this.selectedRange || 'Not specified'}, ` +
            `Cruise Speed: ${this.selectedCruiseSpeed || 'Not specified'}, ` +
            `Service Ceiling: ${this.selectedServiceCeiling || 'Not specified'}, ` +
            `Takeoff/Landing Distance: ${this.selectedTakeoffLandingDistance || 'Not specified'}, ` +
            `Passenger Capacity: ${this.selectedPassengerCapacity || 'Not specified'}, ` +
            `Cargo Capacity: ${this.selectedCargoCapacity || 'Not specified'}, ` +
            `Fuel Efficiency: ${this.selectedFuelEfficiency || 'Not specified'}, ` +
            `Avionics Technology: ${this.selectedAvionicsTechnology || 'Not specified'}, ` +
            `Weather Capabilities: ${this.selectedWeatherCapabilities || 'Not specified'}, ` +
            `Other Considerations: ${this.otherUserConsiderations || 'None'}.`;
    },
    handleRestart() {
      this.apiResponse = null;
    },
  },
};
</script>

<style scoped>
.dialog-class {
  width: 80vw;
}

.overlay-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.scrollable-card-text {
  overflow-y: auto;
}

.title-text {
  color: #0070BA;
}

.custom-subheader-wrapper {
  width: 95%;
  font-weight: bold;
  margin-bottom: 16px;
}

.subtitle-wrap {
  word-wrap: break-word;
  white-space: normal;
  text-align: center;
}

.custom-list-subheader {
  background-color: #f5f5f5;
  padding: 12px 16px;
  font-size: 14px;
  letter-spacing: 0.3px;
  color: #636363;
  font-weight: bold;
}

.slider-class {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
}

.slider-text {
  width: 125px;
  text-align: center;
}

.loading-container {
  min-height: 70vh;
  margin: 20px;
  overflow-y: auto;
}

.loading-text {
  color: #0070BA;
  font-weight: bold;
  padding-top: 20px;
  font-size: large;
}

.response-container {
  min-height: 70vh;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
  overflow-y: auto;
}

.api-response-style {
  padding: 20px;
  margin: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

@media (max-width: 600px) {
  .dialog-class {
    width: 100vw;
  }

  .slider-text {
    font-size: 10px;
    width: 70px;
    padding-inline: 0px;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .response-container {
    min-height: 70vh;
    padding-bottom: 40px;
    padding-left: 10px;
    padding-right: 10px;
    overflow-y: auto;
  }
}
</style>
