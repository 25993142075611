<!-- src/views/NoAircraftPlaceholder.vue -->

<template>
  <div class="no-aircraft-placeholder">
    <img :src="noAircraftPlaceholder" alt="No aircraft available" />
    <h2 class="placeholder-title">You've Been Shot Down</h2>
    <p class="placeholder-text">
      Looks like there are no listings to display. Please update your search or filter criteria and
      try again.
    </p>
  </div>
</template>

<script>
import noAircraftPlaceholder from "@/assets/no-aircraft.png";

export default {
  name: "NoAircraftPlaceholder",
  data() {
    return {
      noAircraftPlaceholder,
    };
  },
};
</script>

<style>
.no-aircraft-placeholder {
  text-align: center;
  padding: 50px;
}
.no-aircraft-placeholder img {
  width: 300px;
}

.placeholder-title {
  margin-top: 10px;
  font-size: 1.5em;
  color: #0070ba;
}
.placeholder-text {
  color: #2e3644;
  margin-top: 10px;
}
</style>
