<!-- src/components/AircraftCard.vue -->

<template>
  <!-- Main card container that displays aircraft information -->
  <v-card @click="openOverlay" class="card-container">
    <!-- Section for displaying aircraft image -->
    <div v-if="imageError" class="image-container">
      <v-icon size="large">mdi-image-off</v-icon>
    </div>
    <div v-else class="image-container" :style="{ backgroundImage: `url(${displayedImage})` }">
      <!-- Display highlight chip if the aircraft has a highlight attribute -->
      <v-chip v-if="aircraft.highlight" class="highlight-chip" variant="elevated" color="gray">
        {{ aircraft.highlight }}
      </v-chip>
    </div>

    <!-- Title section showing aircraft year, manufacturer, and model -->
    <v-card-title class="reduced-margin">
      {{ aircraft.year }} {{ aircraft.manufacturer }} {{ aircraft.model }}
    </v-card-title>

    <!-- Subtitle showing formatted aircraft price -->
    <v-card-subtitle class="reduced-margin"> ${{ formatPrice(aircraft.price) }} </v-card-subtitle>

    <!-- Description text of the aircraft -->
    <v-card-text class="truncate-text">
      {{ aircraft.description }}
    </v-card-text>
  </v-card>
</template>

<script>
import { storage, ref, getDownloadURL } from "@/firebaseConfig";

export default {
  props: {
    aircraft: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      displayedImage: "",
      imageError: false,
    };
  },
  methods: {
    // Formats the price value to a more readable format
    formatPrice(value) {
      return new Intl.NumberFormat().format(value);
    },
    // Emits an event to open an overlay with the aircraft details
    openOverlay() {
      this.$emit("openOverlay", this.aircraft);
    },
    // Asynchronously fetches the image from Firebase storage
    async fetchImage() {
      const gsUrl = this.aircraft.images[0];
      // Append cache-busting query string to ensure fresh image is fetched
      const urlWithCacheBusting = `${gsUrl}?ts=${new Date().getTime()}`;
      const relativePath = urlWithCacheBusting.replace("gs://winglet-dev-d6f12.appspot.com/", "");
      const imageRef = ref(storage, relativePath);
      try {
        const url = await getDownloadURL(imageRef);
        this.displayedImage = url;
      } catch (error) {
        this.imageError = true;
      }
    },
  },
  watch: {
    "aircraft.images": {
      deep: true,
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.fetchImage();
        }
      },
    },
  },
  created() {
    this.fetchImage();
  },
};
</script>

<style scoped>
.card-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-bottom: 16px;
  height: 365px;
}

.image-container {
  height: 200px;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
}

.image-container > .v-icon {
  font-size: 4rem;
  color: rgb(167, 167, 167);
}

.reduced-margin {
  margin-bottom: 0;
}

.v-card-title {
  padding-top: 8px;
  padding-bottom: 4px;
}

.v-card-subtitle {
  padding-top: 4px;
  padding-bottom: 2px;
}

.truncate-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  font-size: 1rem;
  line-height: 1.25rem;
  height: 4.5rem;
  margin-bottom: 2px;
}

.highlight-chip {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  color: white;
}

@media (max-width: 600px) {
  .truncate-text {
    -webkit-line-clamp: 3;
    height: 1.5rem;
  }
}
</style>
