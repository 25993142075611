<!-- src/components/EditProfileOverlay.vue -->

<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
      <v-card-title class="custom-title">Edit Profile</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-text-field
            label="First Name"
            clearable
            variant="outlined"
            color="primary"
            v-model="editedUserDetails.firstName"
          ></v-text-field>
          <v-text-field
            label="Last Name"
            clearable
            variant="outlined"
            color="primary"
            v-model="editedUserDetails.lastName"
          ></v-text-field>
          <v-text-field
            label="email"
            variant="outlined"
            color="primary"
            disabled
            v-model="editedUserDetails.email"
          ></v-text-field>
          <v-text-field
            label="Phone"
            maxlength="14"
            clearable
            variant="outlined"
            color="primary"
            v-model="editedUserDetails.phone"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" variant="text" @click="close">Cancel</v-btn>
        <v-btn color="primary" variant="flat" class="font-weight-bold" @click="save"> Save </v-btn>
      </v-card-actions>
      <v-progress-linear v-if="isSubmitting" indeterminate color="primary"></v-progress-linear>
    </v-card>
  </v-dialog>
</template>

<script>
import { doc, updateDoc, getFirestore } from "firebase/firestore";

export default {
  name: "EditProfileOverlay",
  props: {
    userDetails: Object,
    uid: String,
  },
  data() {
    return {
      dialog: false,
      valid: true,
      isSubmitting: false,
      editedUserDetails: {
        firstName: "",
        lastName: "",
        phone: "",
      },
    };
  },
  methods: {
    open() {
      this.dialog = true;
      this.editedUserDetails = { ...this.userDetails };
    },
    close() {
      this.dialog = false;
      this.$emit("cancel");
    },
    async save() {
      this.isSubmitting = true;

      if (this.$refs.form.validate()) {
        const updateData = {
          // Always include firstName, lastName, and phone in the update.
          // If they are empty strings, the fields in the database are cleared.
          firstName: this.editedUserDetails.firstName || null,
          lastName: this.editedUserDetails.lastName || null,
          phone: this.editedUserDetails.phone || null,
        };

        const db = getFirestore();
        const userDocRef = doc(db, "users", this.uid);

        try {
          await updateDoc(userDocRef, updateData);
          this.dialog = false;
          this.$emit("save");
          this.isSubmitting = false;
        } catch (error) {
          this.isSubmitting = false;
          // TODO: provide feedback to the user via snackbar
        }
      }
    },
  },
  watch: {
    userDetails: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.editedUserDetails = { ...newVal };
        }
      },
    },
  },
};
</script>

<style scoped>
.custom-title {
  font-weight: bold;
  color: #0070ba;
}
</style>
