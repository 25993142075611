// src/plugins/vuetify.js

import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@mdi/font/css/materialdesignicons.min.css";

const myCustomLightTheme = {
  dark: false,
  colors: {
    primary: "#0070BA", // Serves as the main color
    secondary: "#2E3644", // Slightly darkened for better contrast and cohesion
    accent: "#76257B", // Subdued to better align with primary color
    success: "#2E9E5B", // Adjusted for a more natural green
    error: "#D32F2F", // Softened to reduce harshness
    info: "#FFB300", // A bit darker for better readability
    warning: "#FBC02D",
  },
};

export default createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: "myCustomLightTheme",
    themes: {
      myCustomLightTheme,
    },
  },
});
