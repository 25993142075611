<!-- src/components/FilterChips.vue -->

<template>
  <div class="filter-chips">
    <span class="filter-label">Filters:</span>
    <v-chip
      v-for="(value, key) in nonEmptyFilters"
      :key="key"
      color="primary"
      class="ma-2"
      :closable="shouldChipBeClosable(key)"
      @click:close="shouldChipBeClosable(key) && removeFilter(key)"
    >
      {{ formatLabel(key, value) }}
    </v-chip>
  </div>
</template>

<script>
export default {
  name: "FilterChips",
  computed: {
    nonEmptyFilters() {
      const { filters } = this.$store.state;
      const nonEmpty = {};
      Object.entries(filters).forEach(([key, value]) => {
        if (value && (Array.isArray(value) ? value.length : true)) {
          nonEmpty[key] = value;
        }
      });
      return nonEmpty;
    },
  },
  methods: {
    shouldChipBeClosable(key) {
      // Do not allow 'price' and 'year' chips to be closable
      return !(key === "price" || key === "year");
    },
    removeFilter(filterType) {
      this.$store.dispatch("setFilter", { filterType, value: null });
    },
    formatLabel(key, value) {
      let formattedKey = key;
      let formattedValue = value;

      if (formattedKey === "aircraftClass") {
        formattedKey = "Class";
      }

      if (!formattedValue || (Array.isArray(formattedValue) && !formattedValue.length)) {
        return null;
      }

      if (
        formattedKey.toLowerCase().includes("price") &&
        Array.isArray(formattedValue) &&
        formattedValue.length === 2
      ) {
        const minValue = parseFloat(formattedValue[0]);
        const maxValue = parseFloat(formattedValue[1]);

        if (!Number.isNaN(minValue) && !Number.isNaN(maxValue)) {
          const formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });

          const formattedMin = formatter.format(minValue);
          const formattedMax = formatter.format(maxValue);

          formattedValue = `${formattedMin} - ${formattedMax}`; // Represent as a range
        }
      } else if (
        formattedKey.toLowerCase().includes("year") &&
        Array.isArray(formattedValue) &&
        formattedValue.length === 2
      ) {
        formattedValue = `${formattedValue[0]} - ${formattedValue[1]}`;
      } else if (Array.isArray(formattedValue)) {
        formattedValue = formattedValue.join(", ");
      } else if (typeof formattedValue === "string") {
        formattedValue = formattedValue.charAt(0).toUpperCase() + formattedValue.slice(1);
      }

      formattedKey = formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);

      return `${formattedKey}: ${formattedValue}`;
    },
  },
};
</script>

<style scoped>
.filter-chips {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.filter-label {
  margin-right: 10px;
  margin-left: 10px;
  font-weight: bold;
  color: #0070ba;
}
</style>
