<!-- src/components/AircraftOverlay.vue -->

<template>
  <!-- Overlay for displaying aircraft details -->
  <v-overlay :model-value="overlayVisible" @input="updateVisibility" class="overlay-centering">
    <div class="button-container">
      <v-btn
        prepend-icon="mdi-close-circle"
        class="dismiss-overlay-btn"
        v-show="isSmallScreen"
        density="default"
        color="error"
        variant="text"
        @click="hideOverlay"
      >
        Dismiss Overlay
      </v-btn>
      <v-btn
        prepend-icon="mdi-camera"
        class="dismiss-overlay-btn"
        v-show="isSmallScreen"
        density="default"
        color="primary"
        variant="text"
        @click="openImageOverlay(0)"
      >
        View Photos
      </v-btn>
    </div>

    <!-- Card layout within the overlay -->
    <v-card class="overlay-card">
      <v-container>
        <v-row no-gutters class="fill-height">
          <!-- Column for main and additional images -->
          <v-col cols="12" md="6" class="scrollable-images">
            <!-- Main image of the aircraft -->
            <v-img
              v-if="aircraftData && aircraftData.images && aircraftData.images.length > 0"
              :src="aircraftData?.images[0]"
              :alt="aircraftData?.model"
              aspect-ratio="1"
              class="bg-grey-lighten-2 full-width-image"
              cover
              @click="openImageOverlay(0)"
            >
              <!-- Placeholder while the image is loading -->
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-row>
              </template>
            </v-img>

            <!-- Thumbnails for additional images -->
            <v-row no-gutters>
              <v-col
                v-for="(image, index) in additionalImages"
                :key="image"
                cols="6"
                class="d-flex child-flex"
              >
                <v-img
                  :src="image"
                  :alt="aircraftData?.model"
                  aspect-ratio="1"
                  class="bg-grey-lighten-2"
                  cover
                  @click="openImageOverlay(index + 1)"
                >
                  <!-- Placeholder content for additional images -->
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
            </v-row>
          </v-col>

          <!-- Column for aircraft detailed information -->
          <v-col cols="12" md="6">
            <div class="scrollable-content">
              <v-card-title class="font-weight-bold">
                {{ aircraft?.year }} {{ aircraft?.manufacturer }} {{ aircraft?.model }}
                <v-chip class="ml-2" v-if="aircraft.highlight" color="primary" variant="outlined">
                  {{ aircraft?.highlight }}
                </v-chip>
              </v-card-title>

              <!-- Subtitle showing formatted price of the aircraft -->
              <v-card-subtitle> ${{ formatPrice(aircraft?.price) }} </v-card-subtitle>

              <!-- Box for classification details chips -->
              <v-row align="center" justify="start" class="pa-2 ma-2">
                <v-chip
                  v-for="detail in aircraftDetails.classificationDetails"
                  :key="detail.label"
                  color="secondary"
                  class="ma-1"
                >
                  <v-icon class="mr-1">
                    {{ detail.icon }}
                  </v-icon>
                  {{ detail.value }}
                </v-chip>
              </v-row>

              <!-- Section for aircraft details -->
              <div class="mt-4 ml-4 font-weight-bold" style="color: #0070ba">Aircraft Details</div>
              <div class="details-section mt-4 ml-4">
                <v-row dense>
                  <v-col
                    v-for="detail in aircraftDetails.generalDetails"
                    :key="detail.label"
                    class="detail-col"
                  >
                    <v-icon size="small">{{ detail.icon }}</v-icon>
                    <span class="detail-label">{{ detail.label }}</span
                    >: {{ detail.value }}
                  </v-col>
                </v-row>
                <hr class="my-4" />
                <v-row dense>
                  <v-col
                    v-for="detail in aircraftDetails.timeDetails"
                    :key="detail.label"
                    class="detail-col"
                  >
                    <v-icon size="small">{{ detail.icon }}</v-icon>
                    <span class="detail-label">{{ detail.label }}</span
                    >: {{ detail.value }}
                  </v-col>
                </v-row>
              </div>

              <!-- Description of the aircraft -->
              <div class="mt-4 ml-4 font-weight-bold" style="color: #0070ba">Description</div>
              <v-card-text class="overlay-description">
                {{ aircraft?.description }}
              </v-card-text>

              <!-- Avionics information -->
              <div class="ml-4 font-weight-bold" style="color: #0070ba">Avionics</div>
              <div class="ml-4 mt-2 mb-2">
                <v-chip
                  v-for="avionics in aircraft?.avionics"
                  :key="avionics"
                  size="small"
                  class="ma-1"
                  color="primary"
                  label
                >
                  {{ avionics }}
                </v-chip>
                <span v-if="!aircraft?.avionics || !aircraft?.avionics.length">
                  <v-card-text class="avionics-text">
                    There are no avionics to display for this bird...
                  </v-card-text>
                </span>
              </div>

              <!-- Equipment information -->
              <div class="ml-4 font-weight-bold" style="color: #0070ba">Equipment</div>
              <div class="ml-4 mt-2 mb-2">
                <v-chip
                  v-for="equipment in aircraft?.equipment"
                  :key="equipment"
                  size="small"
                  class="ma-1"
                  color="primary"
                  label
                >
                  {{ equipment }}
                </v-chip>
                <span v-if="!aircraft?.equipment || !aircraft?.equipment.length">
                  <v-card-text class="avionics-text">
                    There is no equipment to display for this bird....
                  </v-card-text>
                </span>
              </div>

              <!-- Seller contact information -->
              <div class="mt-4 ml-4 font-weight-bold" style="color: #0070ba">
                Seller Contact Information
              </div>
              <div class="contact-section mt-4 ml-4">
                <v-row dense>
                  <v-col
                    v-for="contactDetail in contactDetails"
                    :key="contactDetail.label"
                    cols="12"
                  >
                    <v-icon size="small">{{ contactDetail.icon }}</v-icon>
                    <span class="contact-label">{{ contactDetail.label }}</span
                    >: {{ contactDetail.value }}
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <!-- Image overlay component for full-screen image viewing -->
    <ImageOverlay
      :images="aircraftData?.images"
      :carouselVisible="showImageOverlay"
      @update:carouselVisible="updateImageOverlayVisibility"
      ref="imageOverlay"
    />
  </v-overlay>
</template>

<script>
import { storage, ref, getDownloadURL } from "@/firebaseConfig";
import ImageOverlay from "./ImageOverlay.vue";

export default {
  name: "AircraftOverlay",
  components: {
    ImageOverlay, // Component for full-screen image viewing
  },
  props: {
    overlayVisible: {
      type: Boolean,
      default: false, // Determines if the overlay is visible
    },
    aircraft: {
      type: Object,
      default: null, // Aircraft data passed to the component
    },
  },
  data() {
    return {
      showImageOverlay: false, // Controls visibility of the image overlay
      aircraftData: null, // Local copy of aircraft data
      isSmallScreen: window.innerWidth < 960,
    };
  },
  watch: {
    aircraft: {
      // Watcher for aircraft prop changes
      handler(newVal) {
        this.aircraftData = { ...newVal }; // Update local copy on change
        this.fetchImages(); // Fetch images when aircraft data changes
      },
      immediate: true, // Run the handler immediately on component creation
    },
    overlayVisible(newVal) {
      if (!newVal) {
        // Perform any additional logic needed when the overlay is hidden
      }
    },
  },
  methods: {
    // Fetches images from Firebase storage and updates aircraftData
    async fetchImages() {
      // Exit the function early if there are no images in aircraftData
      if (!this.aircraftData?.images) {
        return;
      }

      // Fetching each image URL from Firebase storage
      const imageUrls = await Promise.all(
        this.aircraftData.images.map(async (gsUrl) => {
          const relativePath = gsUrl.replace("gs://winglet-dev-d6f12.appspot.com/", "");
          const imageRef = ref(storage, relativePath);
          try {
            return await getDownloadURL(imageRef);
          } catch (error) {
            // If an image fails to load, return null
            return null;
          }
        })
      );

      // Update aircraftData with fetched image URLs, filtering out null values
      this.aircraftData.images = imageUrls.filter(Boolean);
    },
    // Hide the overlay and emit event to parent component
    hideOverlay() {
      this.$emit("closeAircraftOverlay"); // Emit an event to the parent component
    },
    // Update visibility state and emit to parent
    updateVisibility(value) {
      this.$emit("update:overlayVisible", value);
    },
    // Format price for display
    formatPrice(value) {
      if (!value) return "0";
      return new Intl.NumberFormat().format(value);
    },
    // Open full-screen image overlay
    openImageOverlay(index) {
      // Exit the function early if the image overlay component is not available
      if (!this.$refs.imageOverlay) {
        return;
      }

      // Proceed with opening the image overlay
      this.showImageOverlay = true;
      this.$refs.imageOverlay.setCurrentImageIndex(index);
    },
    // Update visibility of the image overlay
    updateImageOverlayVisibility(value) {
      this.showImageOverlay = value;
    },
    checkScreenSize() {
      this.isSmallScreen = window.innerWidth < 960;
    },
  },
  computed: {
    // Contains logic to organize and format aircraft details
    aircraftDetails() {
      const hasSecondEngineTime = !!this.aircraftData?.secondEngineTime;
      const hasSecondPropTime = !!this.aircraftData?.secondPropTime;
      const isHelicopter = this.aircraftData?.aircraftCategory === "Helicopter";
      const isJet = this.aircraftData?.aircraftPowerplant === "Jet";

      const placeholder = "Not Specified";

      const classificationDetails = [
        {
          label: "Category",
          value: this.aircraftData?.aircraftCategory || placeholder,
          icon: "mdi-airplane",
        },
        {
          label: "Class",
          value: this.aircraftData?.aircraftClass || placeholder,
          icon: "mdi-list-box-outline",
        },
        {
          label: "Powerplant",
          value: this.aircraftData?.aircraftPowerplant || placeholder,
          icon: "mdi-lightning-bolt",
        },
      ];

      const generalDetails = [
        {
          label: "Registration",
          value: this.aircraftData?.registration || placeholder,
          icon: "mdi-card-text-outline",
        },
        {
          label: "Serial Number",
          value: this.aircraftData?.serialNumber || placeholder,
          icon: "mdi-numeric",
        },
        { label: "Base", value: this.aircraftData?.base || placeholder, icon: "mdi-airport" },
      ];

      const timeDetails = [
        {
          label: "Total Time",
          value: this.aircraftData?.totalTime || placeholder,
          icon: "mdi-clock-outline",
        },
      ];

      // Add Engine Time and optionally its Time Since Overhaul
      const engineDetails = [
        {
          label: hasSecondEngineTime ? "Engine 1 Since New" : "Engine Since New",
          value: this.aircraftData?.engineTime || placeholder,
          icon: "mdi-engine",
        },
      ];

      if (this.aircraftData?.engineOverhauled && this.aircraftData?.engineTimeSinceOverhaul) {
        engineDetails.push({
          label: hasSecondEngineTime ? "Engine 1 Since Overhaul" : "Engine Since Overhaul",
          value: this.aircraftData?.engineTimeSinceOverhaul || placeholder,
          icon: "mdi-engine",
        });
      }

      timeDetails.push(...engineDetails);

      // If there's a second engine
      if (hasSecondEngineTime) {
        const secondEngineDetails = [
          {
            label: "Engine 2 Since New",
            value: this.aircraftData?.secondEngineTime || placeholder,
            icon: "mdi-engine",
          },
        ];

        if (
          this.aircraftData?.secondEngineOverhauled &&
          this.aircraftData?.secondEngineTimeSinceOverhaul
        ) {
          secondEngineDetails.push({
            label: "Engine 2 Since Overhaul",
            value: this.aircraftData?.secondEngineTimeSinceOverhaul || placeholder,
            icon: "mdi-engine",
          });
        }

        timeDetails.push(...secondEngineDetails);
      }

      if (!isHelicopter && !isJet) {
        // Add Prop Time and optionally its Time Since Overhaul
        const propDetails = [
          {
            label: hasSecondPropTime ? "Prop 1 Since New" : "Prop Since New",
            value: this.aircraftData?.propTime || placeholder,
            icon: "mdi-fan",
          },
        ];

        // Assuming propOverhauled and propTimeSinceOverhaul properties exist in aircraftData
        if (this.aircraftData?.propOverhauled && this.aircraftData?.propTimeSinceOverhaul) {
          propDetails.push({
            label: hasSecondPropTime ? "Prop 1 Since Overhaul" : "Prop Since Overhaul",
            value: this.aircraftData?.propTimeSinceOverhaul || placeholder,
            icon: "mdi-fan",
          });
        }

        timeDetails.push(...propDetails);

        // If there's a second prop
        if (hasSecondPropTime) {
          const secondPropDetails = [
            {
              label: "Prop 2 Since New",
              value: this.aircraftData?.secondPropTime || placeholder,
              icon: "mdi-fan",
            },
          ];

          // Assuming secondPropOverhauled and secondPropTimeSinceOverhaul properties exist in aircraftData
          if (
            this.aircraftData?.secondPropOverhauled &&
            this.aircraftData?.secondPropTimeSinceOverhaul
          ) {
            secondPropDetails.push({
              label: "Prop 2 Since Overhaul",
              value: this.aircraftData?.secondPropTimeSinceOverhaul || placeholder,
              icon: "mdi-fan",
            });
          }

          timeDetails.push(...secondPropDetails);
        }
      }

      return {
        classificationDetails,
        generalDetails,
        timeDetails,
      };
    },
    // Organizes contact information for the aircraft seller
    contactDetails() {
      const placeholder = "Not Specified"; // Placeholder text

      return [
        {
          label: "Name",
          value: this.aircraftData?.contactName || placeholder,
          icon: "mdi-account",
        },
        {
          label: "Phone",
          value: this.aircraftData?.contactPhone || placeholder,
          icon: "mdi-phone",
        },
        {
          label: "Email",
          value: this.aircraftData?.contactEmail || placeholder,
          icon: "mdi-email",
        },
      ];
    },
    // Returns an array of images excluding the first one
    additionalImages() {
      // Check if aircraftData and aircraftData.images are defined
      if (this.aircraftData && this.aircraftData.images) {
        // Return the images array excluding the first one
        return this.aircraftData.images.slice(1);
      }
      // Return an empty array if aircraftData or aircraftData.images are not defined
      return [];
    },
  },
  // On component creation, initialize aircraft data and fetch images
  created() {
    this.aircraftData = { ...this.aircraft };
    this.fetchImages();
    window.addEventListener("resize", this.checkScreenSize); // Add resize listener
    this.checkScreenSize(); // Initialize the screen size check
  },
  beforeMount() {
    this.checkScreenSize(); // Ensure the screen size is checked before mounting
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreenSize); // Clean up the listener
  },
};
</script>

<style scoped>
.overlay-centering {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: row;
}

.overlay-card {
  height: 95vh;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.scrollable-images,
.scrollable-content {
  flex: 1;
  overflow-y: auto;
  width: 100%;
  max-height: 90vh;
  padding-right: 4px;
  box-sizing: border-box;
  background-color: white;
}

.overlay-image {
  max-width: 100%;
  max-height: 100%;
}

.overlay-description {
  white-space: pre-line; /* This will preserve line breaks */
}

.full-width-image {
  width: 100%;
  height: auto;
  background-color: gray;
  box-sizing: border-box;
  border-radius: 15px;
}

.scrollable-images {
  background-color: white; /* This will make sure padding areas are visible as white spaces */
  overflow-x: hidden;
}

.scrollable-images .v-img {
  cursor: pointer !important;
  width: calc(100% - 8px); /* Adjust width to account for margin */
  margin: 4px; /* This creates space around each image */
  padding: 4px; /* Padding inside the image container */
  box-sizing: border-box;
  border-radius: 5px;
}

.details-section {
  background-color: rgba(0, 112, 186, 0.1);
  padding: 15px;
  border-radius: 5px;
}

.detail-col {
  flex-basis: 100%;
  max-width: 100%;
}

.detail-label {
  font-weight: bold;
  margin-left: 5px;
}

.detail-title {
  margin-bottom: 10px;
}

.contact-section {
  background-color: rgba(0, 112, 186, 0.1);
  padding: 15px;
  border-radius: 5px;
}

.contact-title {
  margin-bottom: 10px;
  color: black;
}

.contact-label {
  font-weight: bold;
  margin-left: 5px;
}

.avionics-text {
  color: #3e4756;
  margin-left: -16px;
}

.scrollable-images::-webkit-scrollbar,
.scrollable-content::-webkit-scrollbar {
  width: 10px;
}
.scrollable-images::-webkit-scrollbar-thumb,
.scrollable-content::-webkit-scrollbar-thumb {
  background-color: rgba(207, 207, 207, 0.8);
  border-radius: 5px;
}
.scrollable-images::-webkit-scrollbar-track,
.scrollable-content::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.v-dialog .carousel-section {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.v-carousel {
  flex-grow: 1;
}

.carousel-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

.button-container {
  position: absolute;
  top: 10vh;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  display: flex;
  align-items: center;
}

.dismiss-overlay-btn {
  margin: 0 5px;
}

/* For small screens */
@media (max-width: 960px) {
  .overlay-card {
    width: 100vw;
    height: 100vh;
    margin: 15px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .scrollable-images {
    display: none;
  }

  .scrollable-content {
    font-size: 0.9rem;
    margin-top: 12vh;
    height: 75vh;
    overflow-y: auto;
    flex: 1 0 auto;
  }
}
</style>
