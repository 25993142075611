// store/index.js

import { createStore } from "vuex";
import { collection } from "firebase/firestore";
import { db, auth, getDocs, query as firebaseQuery, orderBy } from "../firebaseConfig";

export default createStore({
  state: {
    filters: {
      price: [0, 2000000],
      year: [1950, 2023],
    },
    searchQuery: "",
    manufacturerOptions: [],
    user: null,
    aircrafts: [],
    error: null,
  },
  mutations: {
    setAircrafts(state, aircrafts) {
      state.aircrafts = aircrafts;
    },
    setFilter(state, { filterType, value }) {
      if (value === null || (Array.isArray(value) && value.length === 0)) {
        // Delete the filter if the value is null or an empty array
        delete state.filters[filterType];
      } else {
        // Update or add the new value for the filter
        state.filters[filterType] = value;
      }
    },
    setSearchQuery(state, query) {
      state.searchQuery = query;
    },
    resetFilters(state) {
      state.filters = {
        price: [0, 2000000],
        year: [1950, 2023],
      };
    },
    setManufacturerOptions(state, payload) {
      state.manufacturerOptions = payload;
    },
    setUser(state, user) {
      state.user = user;
    },
    setError(state, error) {
      state.error = error;
    },
  },
  actions: {
    async fetchAircrafts({ commit }) {
      // Fetch aircrafts from the database and commit to the state
      const listingsCollection = collection(db, "listings");
      const sortedQuery = firebaseQuery(listingsCollection, orderBy("dateCreated", "desc"));
      try {
        const querySnapshot = await getDocs(sortedQuery);
        const aircrafts = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        commit("setAircrafts", aircrafts);
      } catch (error) {
        commit("setError", error.message || "Failed to fetch aircrafts.");
      }
    },
    setFilter({ commit }, payload) {
      commit("setFilter", payload);
    },
    setSearchQuery({ commit }, query) {
      commit("setSearchQuery", query);
    },
    resetFilters({ commit }) {
      commit("resetFilters");
    },
    checkAuth({ commit }) {
      auth.onAuthStateChanged((user) => {
        commit("setUser", user);
      });
    },
  },
  getters: {
    filteredAircrafts: (state) => {
      const searchKeywords = state.searchQuery.trim().toLowerCase().split(" ");

      return state.aircrafts.filter((aircraft) => {
        const matchesSearch =
          state.searchQuery.trim() === "" ||
          searchKeywords.some(
            (keyword) =>
              aircraft.searchQuery &&
              Array.isArray(aircraft.searchQuery) &&
              aircraft.searchQuery.some((key) => key.toLowerCase().includes(keyword))
          );

        const matchesPrice =
          aircraft.price >= state.filters.price[0] && aircraft.price <= state.filters.price[1];
        const matchesYear =
          aircraft.year >= state.filters.year[0] && aircraft.year <= state.filters.year[1];

        // New filters for class, manufacturer, category, and powerplant
        const matchesClass =
          !state.filters.aircraftClass ||
          state.filters.aircraftClass.includes(aircraft.aircraftClass);
        const matchesManufacturer =
          !state.filters.manufacturer || state.filters.manufacturer.includes(aircraft.manufacturer);
        const matchesCategory =
          !state.filters.category || state.filters.category.includes(aircraft.aircraftCategory);
        const matchesPowerplant =
          !state.filters.powerplant ||
          state.filters.powerplant.includes(aircraft.aircraftPowerplant);

        return (
          matchesSearch &&
          matchesPrice &&
          matchesYear &&
          matchesClass &&
          matchesManufacturer &&
          matchesCategory &&
          matchesPowerplant
        );
      });
    },
  },
});
