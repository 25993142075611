<!-- src/components/ManageListingsOverlay.vue -->

<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card>
      <v-card-title>
        <span class="custom-title">Manage Listings</span>
      </v-card-title>
      <v-card-text>
        <div v-if="listings.length">
          <v-list>
            <v-list-item v-for="(listing, index) in listings" :key="listing.id">
              <v-row no-gutters align="center">
                <v-col cols="9" class="py-0">
                  <v-list-item-title>
                    {{ listing.year }} {{ listing.manufacturer }} {{ listing.model }}
                    <v-list-item-subtitle>
                      Date Created: {{ formatDate(listing.dateCreated) }}
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-col>
                <v-col cols="1.5" class="py-0">
                  <v-btn
                    size="x-small"
                    icon
                    variant="tonal"
                    color="primary"
                    @click="editListing(listing)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="1.5" class="py-0">
                  <v-btn
                    size="x-small"
                    icon
                    variant="tonal"
                    color="error"
                    @click="deleteListing(listing)"
                  >
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-divider
                v-if="index !== listings.length - 1"
                class="my-3"
                style="height: 1px"
              ></v-divider>
            </v-list-item>
          </v-list>
        </div>
        <div v-else-if="errorMessage" class="error-text">{{ errorMessage }}</div>
        <!-- Show 'No listings found' only if there are no listings and no error message -->
        <div v-else>No listings found.</div>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="secondary" variant="text" @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
    <!-- Confirmation Dialog -->
    <v-dialog v-model="confirmDeleteDialog" max-width="400">
      <v-card>
        <v-card-title>
          <span class="custom-title">Delete Confirmation</span>
        </v-card-title>
        <v-card-text> Are you sure you want to delete this listing? </v-card-text>
        <v-card-actions>
          <v-btn variant="text" color="error" @click="confirmDelete">Delete</v-btn>
          <v-btn variant="text" color="secondary" @click="confirmDeleteDialog = false">Back</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
  <edit-listing-overlay
    v-model="isEditing"
    :listing-id="selectedListingId"
    @cancelEdit="closeEditDialog"
    @editSuccess="closeEditDialogSuccess"
    @loadListingError="$emit('loadListingError', $event)"
    @closeEditDueToError="isEditing = false"
  />
</template>

<script>
import { auth, db, query, collection, where, getDocs, orderBy } from "@/firebaseConfig";
import { deleteDoc, doc } from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";
import EditListingOverlay from "./EditListingOverlay.vue";

export default {
  name: "ManageListingsOverlay",
  emits: ["listingDeleted", "listingEdited", "listingDeleteError", "loadListingError"],
  components: {
    EditListingOverlay,
  },
  data() {
    return {
      dialog: false,
      listings: [],
      selectedListing: null,
      confirmDeleteDialog: false,
      isEditing: false,
      selectedListingId: null,
      errorMessage: null,
    };
  },
  methods: {
    open() {
      this.dialog = true;
      if (auth.currentUser) {
        this.fetchListings(auth.currentUser.uid);
      } else {
        this.errorMessage = "No authenticated user.";
      }
    },
    close() {
      this.dialog = false;
    },
    formatDate(timestamp) {
      const date = timestamp.toDate(); // Convert Firestore timestamp to JavaScript Date
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`; // Format as MM/DD/YYYY
    },
    async fetchListings(userId) {
      try {
        const listingsCollection = collection(db, "listings");
        const sortedQuery = query(
          listingsCollection,
          where("userID", "==", userId),
          orderBy("dateCreated", "desc")
        );

        const querySnapshot = await getDocs(sortedQuery);

        this.listings = querySnapshot.docs.map((document) => {
          return { id: document.id, ...document.data() };
        });
      } catch (error) {
        this.errorMessage = "Failed to your load listings. Please try again later.";
      }
    },
    editListing(listing) {
      this.selectedListingId = listing.id; // Set the selected listing ID
      this.isEditing = true; // Open the EditListingOverlay
    },
    closeEditDialog() {
      this.isEditing = false; // Close the EditListingOverlay
      this.selectedListingId = null; // Reset the selected listing ID
    },
    closeEditDialogSuccess() {
      this.isEditing = false; // Close the EditListingOverlay
      this.selectedListingId = null; // Reset the selected listing ID
      this.dialog = false; // Close the ManageListingsOverlay
      this.$emit("listingEdited");
    },
    deleteListing(listing) {
      this.selectedListing = listing;
      this.confirmDeleteDialog = true;
    },
    async confirmDelete() {
      try {
        // Step 1: Delete from Firestore
        await this.deleteListingFromFirestore(this.selectedListing.id);

        // Step 2: Delete associated images from Firebase Storage
        if (this.selectedListing.images && this.selectedListing.images.length > 0) {
          await this.deleteImagesFromStorage(this.selectedListing.images);
        }

        // Step 3: Update local state
        this.listings = this.listings.filter((l) => l.id !== this.selectedListing.id);

        // Close confirmation dialog
        this.confirmDeleteDialog = false;
        this.selectedListing = null;
        this.$emit("listingDeleted");
      } catch (error) {
        this.$emit("listingDeleteError", error.message);
      }
    },
    async deleteListingFromFirestore(listingId) {
      const listingRef = doc(db, "listings", listingId);
      await deleteDoc(listingRef);
    },
    async deleteImagesFromStorage(imageURLs) {
      const storage = getStorage();

      const deletionPromises = imageURLs.map(async (url) => {
        const path = new URL(url).pathname.split("/o/")[1].split("?")[0].replace(/%2F/g, "/");
        const imageRef = ref(storage, path);
        return deleteObject(imageRef); // Returns a promise
      });

      await Promise.all(deletionPromises); // Delete all images concurrently
    },
  },
};
</script>

<style scoped>
.custom-title {
  font-weight: bold;
  color: #0070ba;
}

.error-text {
  color: #d32f2f;
}
</style>
