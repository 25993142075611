<!-- src/components/HeaderBar.vue -->

<template>
  <v-app-bar
    color="white"
    flat
    extended
    :extension-height="extensionHeight"
    scroll-behavior="elevate"
  >
    <v-btn icon color="primary" @click="toggleNavigationDrawer">
      <v-icon>mdi-menu</v-icon>
    </v-btn>
    <v-app-bar-title class="text-h5 text-primary font-weight-bold"> planeXchange </v-app-bar-title>
    <div>
      <v-menu v-if="user">
        <template v-slot:activator="{ props }">
          <v-btn icon color="primary" class="mr-4" v-bind="props">
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="viewProfile">
            <v-list-item-title>View Profile</v-list-item-title>
          </v-list-item>
          <v-list-item @click="createListing">
            <v-list-item-title>Create Listing</v-list-item-title>
          </v-list-item>
          <v-list-item @click="manageListings">
            <v-list-item-title>Manage Listings</v-list-item-title>
          </v-list-item>
          <v-list-item @click="toggleAuthState">
            <v-list-item-title>Sign Out</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn v-else @click="toggleAuthState" variant="tonal" color="primary" class="mr-4">
        <v-icon start>mdi-account</v-icon>
        Sign In
      </v-btn>
    </div>
    <sign-in
      ref="signInComponent"
      @showSignUp="showSignUp"
      @signInError="handleAuthServiceError"
    ></sign-in>
    <sign-up
      ref="signUpComponent"
      @showEmailVerification="showEmailVerification"
      @signUpError="handleAuthServiceError"
    ></sign-up>
    <email-verification ref="emailVerificationComponent"></email-verification>
    <create-listing-overlay
      ref="createListingOverlay"
      @listingCreated="$emit('listingCreated')"
    ></create-listing-overlay>
    <manage-listings-overlay
      ref="manageListingsOverlay"
      @listingDeleted="handleManageOverlayListingDeleted"
      @listingEdited="handleEditSuccess"
      @listingDeleteError="handleDeleteError"
      @loadListingError="$emit('loadListingError', $event)"
    ></manage-listings-overlay>
    <profile-overlay ref="profileOverlay" :user-profile="user"></profile-overlay>
    <template v-slot:extension>
      <div class="extended-container" v-show="isExtendedCollapsed">
        <v-btn
          prepend-icon="mdi-arrow-expand-down"
          v-if="showExpandButton"
          color="primary"
          class="menu-button mb-4"
          variant="tonal"
          @click="expandExtension"
        >
          Expand Menu
        </v-btn>
      </div>
      <div
        class="extended-container"
        v-show="windowWidth >= 960 || !isExtendedCollapsed"
        :style="{ maxHeight: extensionHeight + 'px' }"
      >
        <div class="top-row">
          <v-btn
            prepend-icon="mdi-arrow-collapse-up"
            v-if="showCollapseButton"
            color="primary"
            class="menu-button"
            variant="tonal"
            @click="collapseExtension"
          >
            Collapse Menu
          </v-btn>
          <search-box />
          <price-slider-dropdown />
          <year-slider-dropdown />
          <manufacturer-dropdown />
          <category-dropdown />
          <class-dropdown />
          <powerplant-dropdown />
          <v-btn @click="clearFilters" color="secondary">
            <v-icon start>mdi-filter-remove</v-icon>
            Reset Filters
          </v-btn>
        </div>
        <div class="filter-chips-container">
          <filter-chips></filter-chips>
        </div>
      </div>
    </template>
  </v-app-bar>
  <v-navigation-drawer
    v-model="navigationDrawer"
    temporary
    color="primary"
    class="navigation-drawer"
  >
    <v-btn icon variant="plain" class="drawer-close" @click="navigationDrawer = false">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <!-- Main content of the drawer -->
    <div class="drawer-content">
      <v-list-item-title class="bold-title">planeXchange</v-list-item-title>
      <v-divider></v-divider>
      <v-list-item
        @click="$emit('openPlaneFinder')"
        title="PlaneFinder AI"
        class="list-items"
      ></v-list-item>
      <v-list-item
        href="https://www.surveymonkey.com/r/6SQHSWF"
        target="_blank"
        link
        class="list-items"
      >
        <v-list-item-title>
          Submit Product Feedback
          <v-icon end size="16">mdi-open-in-new</v-icon>
        </v-list-item-title>
      </v-list-item>

      <!-- Terms of Service List Item -->
      <v-list-item class="list-items" @click="showTermsOfService">
        <v-list-item-title>Terms of Service</v-list-item-title>
      </v-list-item>
    </div>

    <v-spacer></v-spacer>

    <!-- Footer text -->
    <div class="drawer-footer">
      info@planexchange.io
      <div class="footer-subtitle">App Version {{ version }}</div>
    </div>
  </v-navigation-drawer>

  <!-- Terms of Service Dialog -->
  <v-dialog v-model="termsOfServiceDialog" max-width="700px">
    <v-card>
      <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
      <v-card-text v-html="termsOfServiceText"></v-card-text>
    </v-card>
  </v-dialog>
  <filter-snackbar v-model="showFilterSnackbar" />
  <auth-snackbar
    :visible="showAuthSnackbar"
    :message="authSnackbarMessage"
    :color="authSnackbarColor"
  ></auth-snackbar>
</template>

<script>
import { auth } from "@/firebaseConfig";
import termsOfServiceText from "@/assets/TermsOfService.txt";
import SearchBox from "./SearchBox.vue";
import PriceSliderDropdown from "./PriceSliderDropdown.vue";
import YearSliderDropdown from "./YearSliderDropdown.vue";
import ManufacturerDropdown from "./ManufacturerDropdown.vue";
import PowerplantDropdown from "./PowerplantDropdown.vue";
import ClassDropdown from "./ClassDropdown.vue";
import CategoryDropdown from "./CategoryDropdown.vue";
import FilterSnackbar from "./FilterSnackbar.vue";
import FilterChips from "./FilterChips.vue";
import SignIn from "./SignIn.vue";
import SignUp from "./SignUp.vue";
import EmailVerification from "./EmailVerification.vue";
import CreateListingOverlay from "./CreateListingOverlay.vue";
import ManageListingsOverlay from "./ManageListingsOverlay.vue";
import ProfileOverlay from "./ProfileOverlay.vue";
import AuthSnackbar from "./AuthSnackbar.vue";
import packageJson from "../../package.json";

export default {
  name: "HeaderBar",
  emits: [
    "listingCreated",
    "listingError",
    "listingDeleted",
    "listingUpdated",
    "listingDeleteError",
    "loadListingError",
    "openPlaneFinder",
  ],
  components: {
    SearchBox,
    PriceSliderDropdown,
    YearSliderDropdown,
    ManufacturerDropdown,
    PowerplantDropdown,
    ClassDropdown,
    CategoryDropdown,
    FilterSnackbar,
    FilterChips,
    SignIn,
    SignUp,
    EmailVerification,
    CreateListingOverlay,
    ManageListingsOverlay,
    ProfileOverlay,
    AuthSnackbar,
  },
  data() {
    return {
      user: null,
      authUnsubscribe: null,
      extensionHeight: 110,
      windowWidth: window.innerWidth,
      isExtendedCollapsed: false,
      showFilterSnackbar: false,
      showAuthSnackbar: false,
      authSnackbarMessage: "",
      authSnackbarColor: "",
      navigationDrawer: false,
      version: packageJson.version,
      termsOfServiceDialog: false,
      termsOfServiceText,
    };
  },
  mounted() {
    this.updateExtensionHeight();
    window.addEventListener("resize", this.updateExtensionHeight);

    this.authUnsubscribe = auth.onAuthStateChanged((user) => {
      this.user = user;
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateExtensionHeight);

    this.authUnsubscribe();
  },
  computed: {
    showExpandButton() {
      return this.windowWidth <= 960 && this.isExtendedCollapsed;
    },
    showCollapseButton() {
      return this.windowWidth <= 960 && !this.isExtendedCollapsed;
    },
  },
  methods: {
    showLogin() {
      this.$refs.signInComponent.dialog = true;
    },
    clearFilters() {
      this.$store.dispatch("resetFilters");
      this.showFilterSnackbar = true;
      setTimeout(() => {
        this.showFilterSnackbar = false;
      }, 5000);
    },
    updateExtensionHeight() {
      this.windowWidth = window.innerWidth;

      if (this.windowWidth <= 960) {
        this.extensionHeight = this.isExtendedCollapsed ? 50 : 450;
      } else {
        this.extensionHeight = 110; // Default height for wider screens
        this.isExtendedCollapsed = false; // Ensure it's not collapsed on wider screens
      }
    },
    collapseExtension() {
      this.isExtendedCollapsed = true;
      this.extensionHeight = 50;
    },
    expandExtension() {
      this.isExtendedCollapsed = false;
      this.extensionHeight = 450;
    },
    toggleAuthState() {
      if (this.user) {
        auth
          .signOut()
          .then(() => {
            this.showAuthSuccessSnackbar(
              "User signed out successfully! Flight following cancelled..."
            );
          })
          .catch(() => {
            this.showAuthErrorSnackbar("Error signing out.");
          });
      } else {
        this.showLogin();
      }
    },
    showAuthSuccessSnackbar(message) {
      this.authSnackbarMessage = message;
      this.authSnackbarColor = "secondary";
      this.showAuthSnackbar = true;
      setTimeout(() => {
        this.showAuthSnackbar = false;
      }, 5000);
    },
    showAuthErrorSnackbar(message) {
      this.authSnackbarMessage = message;
      this.authSnackbarColor = "error";
      this.showAuthSnackbar = true;
      setTimeout(() => {
        this.showAuthSnackbar = false;
      }, 5000);
    },
    handleAuthServiceError(errorMessage) {
      this.showAuthErrorSnackbar(errorMessage);
    },
    showSignUp() {
      this.$refs.signUpComponent.dialog = true;
    },
    showEmailVerification() {
      this.$refs.emailVerificationComponent.openDialog();
    },
    createListing() {
      this.$refs.createListingOverlay.open();
    },
    manageListings() {
      this.$refs.manageListingsOverlay.open();
    },
    viewProfile() {
      this.$refs.profileOverlay.open();
    },
    handleManageOverlayListingDeleted() {
      this.$refs.manageListingsOverlay.close();
      this.$emit("listingDeleted");
    },
    handleEditSuccess() {
      this.$emit("listingUpdated");
    },
    handleDeleteError(errorMessage) {
      this.$emit("listingDeleteError", errorMessage);
    },
    toggleNavigationDrawer() {
      this.navigationDrawer = !this.navigationDrawer;
    },
    showTermsOfService() {
      this.termsOfServiceDialog = true;
    },
  },
  watch: {
    isExtendedCollapsed(newValue) {
      if (newValue) {
        this.extensionHeight = 50;
      } else if (window.innerWidth <= 960) {
        this.extensionHeight = 450;
      } else {
        this.extensionHeight = 110;
      }
    },
  },
};
</script>

<style scoped>
.headline {
  width: 100%;
  flex-grow: 1;
  text-align: left;
  font-weight: bold;
  color: #0070ba;
}

.font-bold {
  font-weight: bold;
}
.text-primary {
  color: var(--v-primary-base);
}

.extended-container {
  transition: max-height 0.3s ease;
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

.top-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.filter-chips-container {
  width: 100%;
  margin-top: 8px; /* Creates space between the chips and the row above */
  display: flex;
  justify-content: flex-start; /* Ensures the chips are left-aligned */
}

.menu-button {
  padding: 8px;
  display: inline-flex;
  align-items: center;
}

.v-navigation-drawer.navigation-drawer {
  width: 25vw !important;
  top: 0px !important;
  height: 100% !important;
  z-index: 1050 !important;
}

.bold-title {
  font-weight: bold;
  padding-bottom: 8px;
}

.list-items {
  margin-top: 8px;
  margin-bottom: 8px;
}

.navigation-drawer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.drawer-content {
  flex: 1; /* Ensures that this part takes up the available space */
  overflow: auto; /* In case of overflow, it will become scrollable */
}

.drawer-footer {
  padding: 16px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.footer-subtitle {
  margin-top: 10px;
  font-size: 0.8em;
}

.drawer-close {
  position: absolute;
  top: 0;
  right: 0;
  margin: 8px;
  z-index: 5; /* Ensure it's above other drawer content */
}

@media (max-width: 960px) {
  .top-row {
    flex-direction: column; /* Stack items vertically */
    align-items: stretch; /* Stretch items to fill the container */
    gap: 8px;
  }

  .top-row > * {
    flex-basis: 100%; /* Take full width */
    margin: 8px 0; /* Vertical margins */
  }

  .top-row search-box {
    order: -1; /* Make it the first item */
    margin: 8px 0; /* Bigger vertical margins */
  }

  .filter-chips-container {
    display: none; /* Hide the filter chips container */
  }

  .v-navigation-drawer.navigation-drawer {
    width: 100% !important;
  }
}
</style>
