// src/services/AuthService.js

import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  confirmPasswordReset,
} from "firebase/auth";
import { auth } from "../firebaseConfig";

class AuthService {
  constructor() {
    this.auth = auth;
  }

  login(email, password) {
    return signInWithEmailAndPassword(this.auth, email, password).catch((error) => {
      let message;
      switch (error.code) {
        case "auth/invalid-email":
          message = "Invalid email address. Please enter a valid email.";
          break;
        // Add more cases as needed for other error codes
        default:
          message = "Readback incorrect... Login failed. Please try again.";
          break;
      }
      throw new Error(message);
    });
  }

  logout() {
    return signOut(this.auth);
  }

  register(email, password) {
    return createUserWithEmailAndPassword(this.auth, email, password)
      .then((userCredential) => {
        return sendEmailVerification(userCredential.user)
          .then(() => userCredential)
          .catch((error) => {
            throw new Error(error.message || "Failed to send email verification.");
          });
      })
      .catch((error) => {
        throw new Error(error.message || "Registration failed.");
      });
  }

  getCurrentUser() {
    return this.auth.currentUser;
  }

  sendPasswordResetEmail(email) {
    return sendPasswordResetEmail(this.auth, email);
  }

  async resetPassword(oobCode, newPassword) {
    try {
      await confirmPasswordReset(this.auth, oobCode, newPassword);
      // Optionally, you can handle post-reset logic here
    } catch (error) {
      // Handle or throw the error as needed
      throw new Error(error.message || "Password reset failed.");
    }
  }

  updatePassword(newPassword) {
    const user = this.auth.currentUser;
    if (user) {
      return user.updatePassword(newPassword);
    }
    throw new Error("No user is signed in.");
  }
}

export default new AuthService();
