<!-- src/components/PasswordResetOverlay.vue -->

<template>
  <v-dialog v-model="showResetForm" width="500">
    <v-card>
      <v-card-title class="custom-title">Reset Password</v-card-title>
      <v-card-text>
        <v-text-field
          :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
          :type="visible ? 'text' : 'password'"
          :rules="passwordRules"
          label="New Password"
          placeholder="Enter your password"
          prepend-inner-icon="mdi-lock-outline"
          color="primary"
          variant="outlined"
          v-model="newPassword"
          @click:append-inner="visible = !visible"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="resetPassword" color="primary">Reset Password</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AuthService from "@/services/AuthService";

export default {
  data() {
    return {
      visible: false,
      newPassword: "",
      oobCode: "",
      showResetForm: false,
    };
  },
  created() {
    const queryParams = this.$route.query;
    this.oobCode = queryParams.oobCode;

    // Check both oobCode and mode are present and mode is specific for password reset action
    if (this.oobCode && queryParams.mode === "resetPassword") {
      this.showResetForm = true;
    }
  },
  methods: {
    async resetPassword() {
      if (!this.newPassword) {
        // Handle error: password is empty
        return;
      }
      try {
        await AuthService.resetPassword(this.oobCode, this.newPassword);
        this.$emit("passwordResetSuccess");
        this.showResetForm = false;
        // Redirect user to home page without oobCode in URL
        this.$router.replace({ path: "/" });
      } catch (error) {
        this.$emit("passwordResetFailed");
      }
    },
  },
  computed: {
    passwordRules() {
      return [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 8) || "Password must be at least 8 characters",
        // You can add more rules as needed
      ];
    },
  },
};
</script>

<style scoped>
.custom-title {
  font-weight: bold;
  color: #0070ba;
}
</style>
