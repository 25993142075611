// manufacturers.js

const aircraftManufacturers = [
  "Aeronca",
  "Airbus",
  "American Champion",
  "Aviat",
  "Beechcraft",
  "Bell",
  "Boeing",
  "Bombardier",
  "Cessna",
  "Cirrus",
  "Commander",
  "CubCrafters",
  "Dassault",
  "Diamond",
  "Embraer",
  "Epic",
  "Eurocopter",
  "Extra",
  "Grumman",
  "Gulfstream",
  "ICON",
  "Kitfox",
  "Lancair",
  "Learjet",
  "Maule",
  "Mooney",
  "Pilatus",
  "Piper",
  "Quest",
  "Rans",
  "Robinson",
  "Sikorsky",
  "Glasair",
  "Tecnam",
  "Vans",
  "Velocity",
];

export default aircraftManufacturers;
