<!-- src/components/FilterSnackbar.vue -->

<template>
  <v-snackbar
    :model-value="showSnackbar"
    @update:modelValue="$emit('update:showSnackbar', false)"
    variant="elevated"
    color="secondary"
    :close-on-content-click="true"
    timeout="5000"
    location="bottom"
  >
    Filters reset. You are now free to roam the airspace.
  </v-snackbar>
</template>

<script>
export default {
  name: "FilterSnackbar",
  props: {
    showSnackbar: {
      type: Boolean,
    },
  },
};
</script>
