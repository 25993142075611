<!-- src/components/AuthSnackbar.vue -->

<template>
  <v-snackbar
    :model-value="internalVisible"
    @update:modelValue="updateVisibility"
    variant="elevated"
    :color="color"
    :close-on-content-click="true"
    location="bottom"
  >
    {{ message }}
  </v-snackbar>
</template>

<script>
export default {
  name: "AuthSnackbar",
  props: {
    message: String,
    color: {
      type: String,
      default: "error",
    },
    visible: Boolean,
  },
  data() {
    return {
      internalVisible: this.visible,
    };
  },
  watch: {
    visible(newVal) {
      this.internalVisible = newVal;
      if (newVal) {
        setTimeout(() => {
          this.internalVisible = false;
        }, 5000);
      }
    },
  },
  methods: {
    updateVisibility(value) {
      this.internalVisible = value;
      this.$emit("update:visible", value);
    },
  },
};
</script>
