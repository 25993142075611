<!-- src/components/PriceSliderDropdown.vue -->

<template>
  <v-menu :close-on-content-click="false">
    <template v-slot:activator="{ props }">
      <v-btn
        :style="{ backgroundColor: '#0070BA', color: 'white' }"
        rounded="xs"
        v-bind="props"
        class="ml-2 mr-2"
      >
        Price
        <v-icon end>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list class="price-dropdown-class">
      <v-list-item>
        <v-range-slider
          v-model="range"
          :max="maxPrice"
          :min="minPrice"
          :step="10000"
          hide-details
          color="primary"
          density="comfortable"
          thumb-color="primary"
          class="custom-slider"
        >
          <template v-slot:prepend>
            <v-text-field
              v-model="formattedMinPrice"
              @blur="updateRangeFromText(0, $event.target.value)"
              hide-details
              single-line
              type="text"
              variant="outlined"
              color="primary"
              density="compact"
              style="width: 110px"
            ></v-text-field>
          </template>
          <template v-slot:append>
            <v-text-field
              v-model="formattedMaxPrice"
              @blur="updateRangeFromText(1, $event.target.value)"
              hide-details
              single-line
              type="text"
              variant="outlined"
              color="primary"
              density="compact"
              style="width: 110px"
            ></v-text-field>
          </template>
        </v-range-slider>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "PriceSliderDropdown",
  computed: {
    ...mapState(["filters"]),
    formattedMinPrice: {
      get() {
        return this.formatNumberWithCommas(this.range[0]);
      },
      set(val) {
        this.range[0] = this.parseFormattedNumber(val);
      },
    },
    formattedMaxPrice: {
      get() {
        return this.formatNumberWithCommas(this.range[1]);
      },
      set(val) {
        this.range[1] = this.parseFormattedNumber(val);
      },
    },
  },
  watch: {
    filters: {
      deep: true,
      handler(newFilters) {
        if (newFilters.price) {
          this.range = newFilters.price;
        } else {
          this.range = [this.minPrice, this.maxPrice];
        }
      },
    },
    range: {
      handler(newVal) {
        this.setFilter({ filterType: "price", value: newVal });
      },
      deep: true,
    },
  },
  data() {
    return {
      range: this.$store.state.filters.price || [0, 2000000],
      minPrice: 0,
      maxPrice: 2000000,
    };
  },
  methods: {
    ...mapActions(["setFilter"]),
    formatNumberWithCommas(x) {
      if (x === undefined || x === null) return "";
      return `$${x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    },
    updateRangeFromText(index, value) {
      const num = this.parseFormattedNumber(value);
      if (!Number.isNaN(num)) {
        this.range[index] = num;
        // Ensure the slider updates
        this.range = [...this.range];
      }
    },
    parseFormattedNumber(val) {
      return parseInt(val.replace(/[$,]/g, ""), 10);
    },
  },
};
</script>

<style>
.custom-slider {
  margin-top: 20px;
}

/* Added a new class for dropdown styling */
.price-dropdown-class {
  width: 600px; /* The default width */
  height: 100px; /* The default height */
}

/* Media query for screens with a max width of 960px */
@media (max-width: 960px) {
  .price-dropdown-class {
    width: 100%; /* On smaller screens, take the full width of the container */
    height: auto; /* Optional: Adjust the height as necessary for smaller screens */
  }
}
</style>
