<!-- src/components/ProfileOverlay.vue -->

<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title class="custom-title">Profile Information</v-card-title>
      <v-card-text v-if="isLoading">
        <v-row align="center" justify="center">
          <v-col cols="12" class="text-center">
            <v-progress-circular color="primary" indeterminate></v-progress-circular>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else>
        <!-- Profile information goes here -->
        <v-container>
          <v-row>
            <v-col cols="12">
              <div><strong>Name:</strong> {{ fullName }}</div>
              <div>
                <strong>Email:</strong>
                {{ userDetails?.email || "Not Specified" }}
              </div>
              <div>
                <strong>Phone:</strong>
                {{ userDetails?.phone ? formatPhoneNumber(userDetails.phone) : "Not Specified" }}
              </div>
              <hr class="my-4" />
              <div><strong>Active Listings:</strong> {{ userListingsCount }}</div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="openEditOverlay">Edit Profile</v-btn>
        <v-btn color="secondary" variant="text" @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
    <EditProfileOverlay
      ref="editProfileOverlay"
      v-if="showEditOverlay"
      :userDetails="userDetails"
      :uid="userProfile.uid"
      @save="refreshProfile"
      @cancel="closeEditOverlay"
    />
  </v-dialog>
</template>

<script>
import { doc, getDoc, getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import EditProfileOverlay from "./EditProfileOverlay.vue";

export default {
  name: "ProfileOverlay",
  components: {
    EditProfileOverlay,
  },
  props: ["userProfile"],
  data() {
    return {
      dialog: false,
      isLoading: true,
      userDetails: null,
      userListingsCount: 0,
      showEditOverlay: false,
    };
  },
  methods: {
    open() {
      this.dialog = true;
      this.fetchUserProfile();
      this.fetchUserListingsCount();
    },
    close() {
      this.dialog = false;
    },
    async fetchUserProfile() {
      this.isLoading = true;

      if (!this.userProfile || !this.userProfile.uid) {
        return;
      }

      const db = getFirestore();
      const userDocRef = doc(db, "users", this.userProfile.uid);

      try {
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          this.userDetails = userDoc.data();
        } else {
          // Silently handle error
          // TODO: provide feedback to the user via snackbar
        }
      } catch (error) {
        // Silently handle error
        // TODO: provide feedback to the user via snackbar
      }

      this.isLoading = false;
    },
    refreshProfile() {
      // This method will be triggered after a successful save in EditProfileOverlay
      this.fetchUserProfile();
      this.fetchUserListingsCount();
    },
    formatPhoneNumber() {
      if (!this.userDetails || !this.userDetails.phone) {
        return ""; // Return empty string if no phone number
      }

      const numbers = this.userDetails.phone.replace(/\D/g, ""); // Remove non-digits
      const char = { 0: "(", 3: ") ", 6: "-" };
      let formattedNumber = "";

      for (let i = 0; i < numbers.length; i += 1) {
        formattedNumber += (char[i] || "") + numbers[i];
      }

      return formattedNumber;
    },
    async fetchUserListingsCount() {
      if (!this.userProfile || !this.userProfile.uid) {
        // Handle the missing user profile UID appropriately
        // e.g., show an error message to the user or send an error report
        return;
      }

      const db = getFirestore();
      const listingsRef = collection(db, "listings");
      const q = query(listingsRef, where("userID", "==", this.userProfile.uid));

      try {
        const querySnapshot = await getDocs(q);
        this.userListingsCount = querySnapshot.size; // Store the count in a data property
      } catch (error) {
        // Silently handle error
        // TODO: provide feedback to the user via snackbar
      }
    },

    openEditOverlay() {
      if (!this.userProfile || !this.userProfile.uid) {
        // Silently handle error
        // TODO: provide feedback to the user via snackbar
        return;
      }
      this.showEditOverlay = true;
      this.$nextTick(() => {
        if (this.$refs.editProfileOverlay) {
          this.$refs.editProfileOverlay.open();
        } else {
          // Silently handle error
          // TODO: provide feedback to the user via snackbar
        }
      });
    },
  },
  computed: {
    fullName() {
      const { firstName, lastName } = this.userDetails || {};
      if (!firstName && !lastName) {
        return "Not Specified";
      }
      return `${firstName || ""} ${lastName || ""}`.trim();
    },
  },
};
</script>

<style scoped>
.custom-title {
  font-weight: bold;
  color: #0070ba;
}
</style>
