<!-- src/components/SearchBox.vue -->

<template>
  <v-text-field
    v-model="searchQuery"
    ref="searchInput"
    clearable
    color="primary"
    density="compact"
    variant="outlined"
    label="Search listings"
    placeholder="Manufacturer, Model, Registration, Serial Number"
    prepend-inner-icon="mdi-magnify"
    single-line
    hide-details
    class="ml-2 mr-2"
    @blur="executeSearch"
    @keyup.enter="executeSearch"
    @click:clear="onClear"
    style="min-width: 200px"
  >
  </v-text-field>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "SearchBox",
  data() {
    return {
      searchQuery: "",
    };
  },
  methods: {
    ...mapActions(["setSearchQuery"]),
    executeSearch() {
      this.setSearchQuery(this.searchQuery ? this.searchQuery.trim() : "");
      if (this.$refs.searchInput) {
        this.$refs.searchInput.blur();
      }
    },
    onClear() {
      this.setSearchQuery("");
      if (this.$refs.searchInput) {
        this.$refs.searchInput.blur();
      }
    },
  },
};
</script>
