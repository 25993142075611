<!-- src/components/YearSliderDropdown.vue -->

<template>
  <v-menu :close-on-content-click="false">
    <template v-slot:activator="{ props }">
      <v-btn
        :style="{ backgroundColor: '#0070BA', color: 'white' }"
        rounded="xs"
        v-bind="props"
        class="ml-2 mr-2"
      >
        Year
        <v-icon end>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list class="dropdown-class">
      <v-list-item>
        <v-range-slider
          v-model="range"
          :max="maxYear"
          :min="minYear"
          :step="1"
          hide-details
          color="primary"
          density="comfortable"
          thumb-color="primary"
          class="custom-slider"
        >
          <template v-slot:prepend>
            <v-text-field
              v-model="range[0]"
              @blur="validateAndSetYear(0, range[0])"
              hide-details
              single-line
              type="text"
              variant="outlined"
              color="primary"
              density="compact"
              style="width: 100px"
            ></v-text-field>
          </template>
          <template v-slot:append>
            <v-text-field
              v-model="range[1]"
              @blur="validateAndSetYear(1, range[1])"
              hide-details
              single-line
              type="text"
              variant="outlined"
              color="primary"
              density="compact"
              style="width: 100px"
            ></v-text-field>
          </template>
        </v-range-slider>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "YearSliderDropdown",
  computed: {
    ...mapState(["filters"]),
  },
  watch: {
    filters: {
      deep: true,
      handler(newFilters) {
        if (newFilters.year) {
          this.range = newFilters.year;
        } else {
          this.range = [this.minYear, this.maxYear];
        }
      },
    },
    range: {
      handler(newVal) {
        this.setFilter({ filterType: "year", value: newVal });
      },
      deep: true,
    },
  },
  data() {
    return {
      range: this.$store.state.filters.year,
      minYear: 1950,
      maxYear: 2023,
    };
  },
  methods: {
    ...mapActions(["setFilter"]),
    validateAndSetYear(index, value) {
      let year = parseInt(value, 10);
      if (!Number.isNaN(year)) {
        year = Math.min(Math.max(year, this.minYear), this.maxYear); // Ensure the year is within range
        this.range[index] = year;
        // Ensure the slider updates
        this.range = [...this.range];
      }
    },
  },
};
</script>

<style>
.custom-slider {
  margin-top: 20px;
}

/* Added a new class for dropdown styling */
.dropdown-class {
  width: 500px; /* The default width */
  height: 100px; /* The default height */
}

/* Media query for screens with a max width of 960px */
@media (max-width: 960px) {
  .dropdown-class {
    width: 100%; /* On smaller screens, take the full width of the container */
    height: auto; /* Optional: Adjust the height as necessary for smaller screens */
  }
}
</style>
