<!-- src/components/EmailVerification.vue -->

<template>
  <v-dialog v-model="dialog" max-width="500px">
    <div class="custom-card mx-auto pa-10" elevation="2" rounded="lg">
      <!-- Header -->
      <div class="header text-h6 font-weight-medium mb-4">Verify Your Email</div>

      <!-- Email Message -->
      <div class="email-message mb-6">
        <div class="text-body-2">
          A verification email has been sent to your email address. Please check your inbox and
          follow the instructions.
        </div>
      </div>

      <!-- Close Button -->
      <v-btn block size="large" color="primary" @click="closeDialog">Close</v-btn>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "EmailVerification",
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    openDialog() {
      this.dialog = true;
    },
  },
};
</script>

<style scoped>
.custom-card {
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}
.header {
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 1.5rem;
}
.email-message {
  margin-bottom: 24px;
  background: #f2f2f2;
  padding: 16px;
  border-radius: 8px;
}
</style>
