<!-- src/views/HomeView.vue -->

<template>
  <div>
    <HeaderBar
      @listingCreated="handleListingCreated"
      @listingError="showErrorSnackbar"
      @listingDeleted="handleListingDeleted"
      @listingUpdated="handleListingUpdated"
      @listingDeleteError="showDeleteErrorSnackbar"
      @loadListingError="showLoadingErrorSnackbar"
      @openPlaneFinder="openPlaneFinderOverlay"
      ref="headerBar"
    />
    <v-main @scroll="handleMainScroll">
      <v-banner
        lines="one"
        color="white"
        bg-color="success"
        class="banner-class"
      >
        <v-icon class="px-4">mdi-airplane</v-icon>
        <v-banner-text class="banner-text">
          New feature alert! Looking for your dream airplane? Check out PlaneFinder AI... simple, smart, and tailored for you.
        </v-banner-text>

        <template v-slot:actions>
          <v-btn variant="tonal" color="white" class="mx-4" @click="openPlaneFinderOverlay">Open PlaneFinder AI</v-btn>
        </template>
      </v-banner>
      <v-container fluid>
        <div v-if="!isFetchingAircraft && filteredAircrafts.length === 0">
          <NoAircraftPlaceholder />
        </div>
        <v-row class="aircraft-row" v-if="isFetchingAircraft">
          <v-col v-for="n in 12" :key="`skeleton-${n}`" cols="12" sm="6" md="4" lg="3">
            <v-skeleton-loader
              class="pa-4"
              type="image, heading, subtitle, text, text, text"
              :loading="isFetchingAircraft"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row class="aircraft-row">
          <v-col
            v-for="aircraft in filteredAircrafts"
            :key="`${aircraft.id}-${aircraft.lastUpdated}`"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <AircraftCard :aircraft="aircraft" @openOverlay="showAircraftOverlay" />
          </v-col>
        </v-row>
        <AircraftOverlay
          :aircraft="selectedAircraft"
          v-model="showOverlay"
          @closeAircraftOverlay="hideAircraftOverlay"
        />
      </v-container>
    </v-main>
    <PlaneFinderOverlay 
      v-model="showPlaneFinderOverlay" 
      @closePlaneFinderOverlay="hidePlaneFinderOverlay"
    />
    <ListingSnackbar
      :show="showListingSnackbar"
      :message="listingSnackbarMessage"
      :color="listingSnackbarColor"
      @update:show="showListingSnackbar = false"
    ></ListingSnackbar>
    <PasswordResetOverlay
      v-if="showPasswordResetOverlay"
      @passwordResetSuccess="showAuthSuccessSnackbar"
      @passwordResetFailed="showAuthErrorSnackbar"
    />
    <auth-snackbar
      :visible="showAuthSnackbar"
      :message="authSnackbarMessage"
      :color="authSnackbarColor"
    ></auth-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import HeaderBar from "@/components/HeaderBar.vue";
import AircraftCard from "@/components/AircraftCard.vue";
import AircraftOverlay from "@/components/AircraftOverlay.vue";
import ListingSnackbar from "@/components/ListingSnackbar.vue";
import AuthSnackbar from "@/components/AuthSnackbar.vue";
import NoAircraftPlaceholder from "@/components/NoAircraftPlaceholder.vue";
import PasswordResetOverlay from "@/components/PasswordResetOverlay.vue";
import PlaneFinderOverlay from "@/components/PlaneFinderOverlay.vue";

export default {
  name: "HomePage",
  components: {
    HeaderBar,
    AircraftCard,
    AircraftOverlay,
    ListingSnackbar,
    AuthSnackbar,
    NoAircraftPlaceholder,
    PasswordResetOverlay,
    PlaneFinderOverlay,
  },
  data() {
    return {
      aircrafts: [],
      showOverlay: false,
      selectedAircraft: null,
      showListingSnackbar: false,
      listingSnackbarMessage: "",
      listingSnackbarColor: "success",
      isFetchingAircraft: false,
      showPasswordResetOverlay: false,
      showAuthSnackbar: false,
      authSnackbarMessage: "",
      authSnackbarColor: "",
      showPlaneFinderOverlay: false,
    };
  },
  async created() {
    this.isFetchingAircraft = true;
    await this.$store.dispatch("fetchAircrafts");
    this.isFetchingAircraft = false;
    this.showPasswordResetOverlay = this.$route.query.oobCode !== undefined;
  },
  methods: {
    handleMainScroll() {
      this.$refs.headerBar.handleScroll();
    },
    showSuccessSnackbar() {
      this.listingSnackbarMessage = "Cleared for takeoff. Listing successfully created!";
      this.listingSnackbarColor = "success";
      this.showListingSnackbar = true;
    },
    showErrorSnackbar() {
      this.listingSnackbarMessage =
        "Negative Ghostrider... Error creating listing. Please try again.";
      this.listingSnackbarColor = "error";
      this.showListingSnackbar = true;
    },
    showAuthSuccessSnackbar() {
      this.authSnackbarMessage = "Password successfully reset!";
      this.authSnackbarColor = "success";
      this.showAuthSnackbar = true;
      setTimeout(() => {
        this.showAuthSnackbar = false;
      }, 5000);
    },
    showAuthErrorSnackbar() {
      this.authSnackbarMessage = "Password reset failed. Please try again.";
      this.authSnackbarColor = "error";
      this.showAuthSnackbar = true;
      setTimeout(() => {
        this.showAuthSnackbar = false;
      }, 5000);
    },
    async handleListingCreated() {
      await this.$store.dispatch("fetchAircrafts");
      this.showSuccessSnackbar();
    },
    async handleListingDeleted() {
      this.showDeleteSuccessSnackbar();
      await this.$store.dispatch("fetchAircrafts");
    },
    async handleListingUpdated() {
      this.showEditSuccessSnackbar();
      await this.$store.dispatch("fetchAircrafts");
    },
    showDeleteSuccessSnackbar() {
      this.listingSnackbarMessage = "Listing successfully deleted! It's now in the no-fly zone...";
      this.listingSnackbarColor = "secondary";
      this.showListingSnackbar = true;
    },
    showEditSuccessSnackbar() {
      this.listingSnackbarMessage =
        "Listing successfully edited! An A&P has signed off on your change...";
      this.listingSnackbarColor = "success";
      this.showListingSnackbar = true;
    },
    showLoadingErrorSnackbar(errorMessage) {
      this.listingSnackbarMessage = errorMessage || "An error occurred loading the listing.";
      this.listingSnackbarColor = "error";
      this.showListingSnackbar = true;
    },
    showDeleteErrorSnackbar(errorMessage) {
      this.listingSnackbarMessage = errorMessage;
      this.listingSnackbarColor = "error";
      this.showListingSnackbar = true;
    },
    formatPrice(value) {
      return new Intl.NumberFormat().format(value);
    },
    showAircraftOverlay(aircraft) {
      this.selectedAircraft = aircraft;
      this.showOverlay = true;
    },
    hideAircraftOverlay() {
      this.showOverlay = false;

      setTimeout(() => {
        this.selectedAircraft = null;
      }, 500); // Delay in milliseconds
    },
    openPlaneFinderOverlay() {
      this.showPlaneFinderOverlay = true;
    },
    hidePlaneFinderOverlay() {
      this.showPlaneFinderOverlay = false;
    },
  },
  computed: {
    ...mapGetters(["filteredAircrafts"]),
    ...mapState({
      storeError: (state) => state.error,
    }),
  },
  watch: {
    storeError(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        this.showLoadingErrorSnackbar(newValue);
      }
    },
  },
};
</script>

<style scoped>
.aircraft-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.overlay-centering {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.overlay-card {
  width: 70vw;
  height: 90vh;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 90rem;
  max-height: 60rem;
}

@media (max-width: 600px) {
  .banner-class {
    display: none;
  }
}
</style>
