<!-- src/components/ListingSnackbar.vue -->

<template>
  <v-snackbar
    :model-value="show"
    @update:modelValue="hide"
    variant="elevated"
    :color="color"
    :close-on-content-click="true"
    timeout="5000"
    location="bottom"
  >
    {{ message }}
  </v-snackbar>
</template>

<script>
export default {
  name: "ListingSnackbar",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    message: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "secondary",
    },
  },
  methods: {
    hide() {
      this.$emit("update:show", false);
    },
  },
};
</script>
