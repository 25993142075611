<!-- src/components/EditListingOverlay.vue -->

<template>
  <v-dialog ref="dialog" v-model="dialog" persistent max-width="80vw">
    <v-card class="overlay-card">
      <v-card-title class="custom-title">Edit Listing</v-card-title>
      <v-card-text class="scrollable-card-text">
        <div class="custom-subheader-wrapper">
          <v-list-subheader class="custom-list-subheader">Aircraft Overview</v-list-subheader>
        </div>
        <v-combobox
          v-model="manufacturer"
          :items="manufacturers"
          label="Manufacturer"
          :rules="requiredRule"
          hint="Select from the list or start typing to create your own."
          variant="outlined"
          clearable
          color="primary"
          @blur="() => handleBlur('manufacturer')"
        ></v-combobox>
        <v-text-field
          v-model="year"
          label="Year"
          type="number"
          :rules="yearRules"
          variant="outlined"
          color="primary"
        ></v-text-field>
        <v-text-field
          v-model="model"
          label="Model"
          :rules="requiredRule"
          variant="outlined"
          clearable
          color="primary"
          @blur="() => handleBlur('model')"
        ></v-text-field>
        <v-text-field
          v-model="price"
          label="Price"
          :rules="requiredRule"
          type="number"
          prefix="$"
          clearable
          color="primary"
          variant="outlined"
        ></v-text-field>
        <v-combobox
          v-model="highlight"
          label="Highlight"
          :rules="requiredRule"
          hint="Select from the list or start typing to create your own."
          maxlength="15"
          counter
          clearable
          variant="outlined"
          color="primary"
          @blur="() => handleBlur('highlight')"
          :items="highlightOptions"
        ></v-combobox>
        <div class="custom-subheader-wrapper">
          <v-list-subheader class="custom-list-subheader">Category</v-list-subheader>
        </div>
        <v-radio-group v-model="aircraftCategory" color="primary" :rules="requiredRule" inline>
          <v-radio label="Airplane" value="Airplane"></v-radio>
          <v-radio label="Helicopter" value="Helicopter"></v-radio>
        </v-radio-group>
        <div class="custom-subheader-wrapper">
          <v-list-subheader class="custom-list-subheader">Class</v-list-subheader>
        </div>
        <v-radio-group v-model="aircraftClass" color="primary" :rules="requiredRule" inline>
          <v-radio label="Single-Engine" value="Single-Engine"></v-radio>
          <v-radio label="Multi-Engine" value="Multi-Engine"></v-radio>
        </v-radio-group>
        <div class="custom-subheader-wrapper">
          <v-list-subheader class="custom-list-subheader">Powerplant</v-list-subheader>
        </div>
        <v-radio-group v-model="aircraftPowerplant" color="primary" :rules="requiredRule" inline>
          <v-radio label="Piston" value="Piston"></v-radio>
          <v-radio
            label="Turboprop"
            value="Turboprop"
            v-bind:disabled="!showTurbopropOption"
          ></v-radio>
          <v-radio label="Jet" value="Jet"></v-radio>
        </v-radio-group>
        <div class="custom-subheader-wrapper">
          <v-list-subheader class="custom-list-subheader">Aircraft Details</v-list-subheader>
        </div>
        <v-text-field
          v-model="registration"
          label="Registration"
          maxlength="6"
          counter
          clearable
          variant="outlined"
          color="primary"
          prepend-icon="mdi-card-text-outline"
          @blur="() => handleBlur('registration')"
        ></v-text-field>
        <v-text-field
          v-model="serialNumber"
          label="Serial Number"
          clearable
          variant="outlined"
          color="primary"
          prepend-icon="mdi-numeric"
          @blur="() => handleBlur('serialNumber')"
        ></v-text-field>
        <v-text-field
          v-model="base"
          label="Base (ICAO)"
          maxlength="4"
          counter
          variant="outlined"
          clearable
          prepend-icon="mdi-airport"
          color="primary"
          @blur="() => handleBlur('base')"
        ></v-text-field>
        <v-text-field
          v-model="totalTime"
          label="Total Time"
          type="number"
          clearable
          variant="outlined"
          color="primary"
          prepend-icon="mdi-clock-outline"
        ></v-text-field>
        <v-row align="center">
          <v-col class="d-flex">
            <v-text-field
              v-model="engineTime"
              :label="engineLabel"
              type="number"
              clearable
              variant="outlined"
              color="primary"
              prepend-icon="mdi-engine"
              class="flex-grow-1"
            ></v-text-field>
          </v-col>
          <v-col cols="auto" class="d-flex">
            <v-switch
              v-model="engineOverhauled"
              color="secondary"
              inset
              label="Overhauled"
              class="flex-none"
            >
            </v-switch>
          </v-col>
        </v-row>
        <v-row v-if="engineOverhauled" align="center" class="mt-n6">
          <v-col>
            <v-text-field
              v-model="engineTimeSinceOverhaul"
              :label="engineOverhaulLabel"
              type="number"
              clearable
              variant="outlined"
              color="primary"
              prepend-icon="mdi-engine"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="aircraftClass === 'Multi-Engine'" align="center" class="mt-n6">
          <v-col class="d-flex">
            <v-text-field
              v-model="secondEngineTime"
              label="Engine 2 Time Since New"
              type="number"
              clearable
              variant="outlined"
              color="primary"
              prepend-icon="mdi-engine"
              class="flex-grow-1"
            ></v-text-field>
          </v-col>
          <v-col cols="auto" class="d-flex">
            <v-switch
              v-model="secondEngineOverhauled"
              color="secondary"
              inset
              label="Overhauled"
              class="flex-none"
            >
            </v-switch>
          </v-col>
        </v-row>
        <v-row
          v-if="aircraftClass === 'Multi-Engine' && secondEngineOverhauled"
          align="center"
          class="mt-n6"
        >
          <v-col>
            <v-text-field
              v-model="secondEngineTimeSinceOverhaul"
              label="Engine 2 Time Since Overhaul"
              type="number"
              clearable
              variant="outlined"
              color="primary"
              prepend-icon="mdi-engine"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" class="mt-n6">
          <v-col class="d-flex">
            <v-text-field
              v-if="showPropTime"
              v-model="propTime"
              :label="propLabel"
              type="number"
              clearable
              variant="outlined"
              color="primary"
              prepend-icon="mdi-turbine"
              class="flex-grow-1"
            ></v-text-field>
          </v-col>
          <v-col cols="auto" class="d-flex">
            <v-switch
              v-if="showPropTime"
              v-model="propOverhauled"
              color="secondary"
              inset
              label="Overhauled"
              class="flex-none"
            >
            </v-switch>
          </v-col>
        </v-row>
        <v-row v-if="propOverhauled" align="center" class="mt-n6">
          <v-col>
            <v-text-field
              v-if="showPropTime"
              v-model="propTimeSinceOverhaul"
              :label="propOverhaulLabel"
              type="number"
              clearable
              variant="outlined"
              color="primary"
              prepend-icon="mdi-turbine"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="aircraftClass === 'Multi-Engine'" align="center" class="mt-n6">
          <v-col class="d-flex">
            <v-text-field
              v-if="showPropTime"
              v-model="secondPropTime"
              label="Prop 2 Time Since New"
              type="number"
              clearable
              variant="outlined"
              color="primary"
              prepend-icon="mdi-turbine"
              class="flex-grow-1"
            ></v-text-field>
          </v-col>
          <v-col cols="auto" class="d-flex">
            <v-switch
              v-if="showPropTime"
              v-model="secondPropOverhauled"
              color="secondary"
              inset
              label="Overhauled"
              class="flex-none"
            >
            </v-switch>
          </v-col>
        </v-row>
        <v-row
          v-if="aircraftClass === 'Multi-Engine' && secondPropOverhauled"
          align="center"
          class="mt-n6"
        >
          <v-col>
            <v-text-field
              v-if="showPropTime"
              v-model="secondPropTimeSinceOverhaul"
              label="Prop 2 Time Since Overhaul"
              type="number"
              clearable
              variant="outlined"
              color="primary"
              prepend-icon="mdi-turbine"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <div class="custom-subheader-wrapper">
          <v-list-subheader class="custom-list-subheader">Listing Description</v-list-subheader>
        </div>
        <v-textarea
          v-model="description"
          clearable
          label="Description"
          :rules="requiredRule"
          variant="outlined"
          color="primary"
        ></v-textarea>
        <div class="custom-subheader-wrapper">
          <v-list-subheader class="custom-list-subheader">Avionics</v-list-subheader>
        </div>
        <v-combobox
          v-model="avionics"
          label="Aircraft Avionics"
          hint="Start typing to add avionics. Press enter to add."
          multiple
          chips
          closable-chips
          clearable
          variant="outlined"
          color="primary"
        ></v-combobox>
        <div class="custom-subheader-wrapper">
          <v-list-subheader class="custom-list-subheader">Equipment</v-list-subheader>
        </div>
        <v-combobox
          v-model="equipment"
          label="Aircraft Equipment"
          hint="Start typing to add equipment. Press enter to add."
          multiple
          chips
          closable-chips
          clearable
          variant="outlined"
          color="primary"
        ></v-combobox>
        <div class="custom-subheader-wrapper">
          <v-list-subheader class="custom-list-subheader"
            >Seller Contact Information</v-list-subheader
          >
        </div>
        <v-text-field
          v-model="contactName"
          label="Name"
          variant="outlined"
          clearable
          prepend-icon="mdi-account"
          color="primary"
          @blur="() => handleBlur('contactName')"
        ></v-text-field>
        <v-text-field
          v-model="contactPhone"
          ref="phoneField"
          label="Phone"
          maxlength="14"
          clearable
          variant="outlined"
          prepend-icon="mdi-phone"
          color="primary"
          @blur="formatPhoneNumber"
          :rules="[...phoneRules, phoneOrEmailRule()]"
        ></v-text-field>
        <v-text-field
          v-model="contactEmail"
          label="Email"
          ref="emailField"
          variant="outlined"
          clearable
          prepend-icon="mdi-email"
          color="primary"
          :rules="[...emailRules, phoneOrEmailRule()]"
        ></v-text-field>
        <div class="custom-subheader-wrapper">
          <v-list-subheader class="custom-list-subheader">Upload Images</v-list-subheader>
        </div>
        <v-row align="center" class="flex-wrap" dense>
          <v-col cols="12" md="10">
            <v-file-input
              v-model="selectedImages"
              chips
              multiple
              accept="image/*"
              variant="outlined"
              label="Aircraft Photos"
              color="primary"
              prepend-icon="mdi-camera"
            ></v-file-input>
          </v-col>
          <v-col cols="12" md="2" class="text-center custom-btn-container">
            <v-btn
              @click="addImages"
              variant="tonal"
              color="primary"
              class="custom-btn mb-6"
              :disabled="!selectedImages.length"
            >
              Click to Add
            </v-btn>
          </v-col>
        </v-row>
        <draggable
          v-model="images"
          @start="drag = true"
          @end="drag = false"
          item-key="index"
          :rules="imageRule"
          class="flex-row"
        >
          <template #item="{ element, index }">
            <v-col :key="index" cols="12" sm="6" md="4" lg="3" class="pa-2 relative-position">
              <div class="image-container elevation-1">
                <v-img :src="getObjectURL(element)" aspect-ratio="1.6" cover></v-img>
              </div>
              <v-icon
                class="position-absolute adjusted-icon"
                color="error"
                @click.stop="removeImage(getObjectURL(element))"
              >
                mdi-close-circle
              </v-icon>
            </v-col>
          </template>
        </draggable>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="error" variant="text" @click="close">Cancel</v-btn>
        <v-btn
          :disabled="!isFormValid"
          color="primary"
          variant="flat"
          class="font-weight-bold"
          @click="submitForm"
          >Save Changes</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-progress-linear v-if="isSubmitting" indeterminate color="primary"></v-progress-linear>
  </v-dialog>
</template>

<script>
import {
  db,
  doc,
  getDoc,
  updateDoc,
  ref,
  storage,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "@/firebaseConfig";
import draggable from "vuedraggable";
import aircraftManufacturers from "../data/aircraftManufacturers";

export default {
  name: "EditListingOverlay",
  components: {
    draggable,
  },
  props: {
    listingId: String,
  },
  data() {
    return {
      // Component state variables
      dialog: false,
      drag: false,
      isSubmitting: false,
      highlightOptions: [
        "Glass Cockpit",
        "IFR",
        "New Paint",
        "New Interior",
        "Fresh Annual",
        "Vintage",
        "Low Time",
        "High Altitude",
        "STOL",
        "Amphibious",
        "Floats",
      ],
      manufacturers: aircraftManufacturers,
      // Aircraft details
      manufacturer: null,
      year: null,
      model: null,
      price: null,
      highlight: null,
      registration: null,
      serialNumber: "",
      base: null,
      totalTime: null,
      engineTime: null,
      engineOverhauled: false,
      engineTimeSinceOverhaul: null,
      secondEngineTime: null,
      secondEngineOverhauled: false,
      secondEngineTimeSinceOverhaul: null,
      propTime: null,
      propOverhauled: false,
      propTimeSinceOverhaul: null,
      secondPropTime: null,
      secondPropOverhauled: false,
      secondPropTimeSinceOverhaul: null,
      // Aircraft types
      aircraftCategory: null,
      aircraftClass: null,
      aircraftPowerplant: null,
      // Listing details
      description: null,
      avionics: [],
      equipment: [],
      // Contact information
      contactName: null,
      contactPhone: null,
      contactEmail: null,
      // Images
      selectedImages: [],
      images: [],
      imagesToDelete: [],
      // Form validation rules
      requiredRule: [(v) => !!v || "Field is required."],
      yearRules: [
        (v) => !!v || "Field is required.",
        (v) =>
          (v ? v >= 1950 && v <= new Date().getFullYear() : true) ||
          "Year must be between 1950 and the current year.",
      ],
      phoneRules: [
        (v) =>
          !v || (v && v.replace(/\D/g, "").length === 10) || "Phone number must have 10 digits.",
      ],
      emailRules: [(v) => !v || /.+@.+\..+/.test(v) || "Email must be valid."],
      imageRule: [(v) => (v && v.length > 0) || "At least 1 image is required."],
    };
  },
  computed: {
    engineLabel() {
      return this.aircraftClass === "Multi-Engine"
        ? "Engine 1 Time Since New"
        : "Engine Time Since New";
    },
    engineOverhaulLabel() {
      return this.aircraftClass === "Multi-Engine"
        ? "Engine 1 Time Since Overhaul"
        : "Engine Time Since Overhaul";
    },
    showTurbopropOption() {
      return this.aircraftCategory !== "Helicopter";
    },
    propLabel() {
      return this.aircraftClass === "Multi-Engine"
        ? "Prop 1 Time Since New"
        : "Prop Time Since New";
    },
    propOverhaulLabel() {
      return this.aircraftClass === "Multi-Engine"
        ? "Prop 1 Time Since Overhaul"
        : "Prop Time Since Overhaul";
    },
    showPropTime() {
      return this.aircraftPowerplant !== "Jet" && this.aircraftCategory !== "Helicopter";
    },
    showSecondPropTime() {
      return (
        (this.aircraftPowerplant === "Turbine" || this.aircraftPowerplant === "Piston") &&
        this.aircraftClass === "Multi-Engine"
      );
    },
    phoneOrEmailRule() {
      // This ensures it re-evaluates whenever contactPhone or contactEmail changes.
      return () =>
        !!this.contactPhone || !!this.contactEmail || "Either Phone or Email is required.";
    },
    isFormValid() {
      // Aircraft Overview
      if (
        this.requiredRule[0](this.manufacturer) !== true ||
        !this.yearRules.every((rule) => rule(this.year) === true) ||
        this.requiredRule[0](this.model) !== true ||
        this.requiredRule[0](this.price) !== true ||
        this.requiredRule[0](this.description) !== true
      ) {
        return false;
      }

      // Aircraft Category
      if (this.requiredRule[0](this.aircraftCategory) !== true) {
        return false;
      }

      // Aircraft Class
      if (this.requiredRule[0](this.aircraftClass) !== true) {
        return false;
      }

      // Aircraft Powerplant
      if (this.requiredRule[0](this.aircraftPowerplant) !== true) {
        return false;
      }

      // Contact Information
      if (
        this.emailRules[0](this.contactEmail) !== true ||
        this.phoneRules[0](this.contactPhone) !== true
      ) {
        return false;
      }

      // Images
      if (this.imageRule[0](this.images) !== true) {
        return false;
      }

      // If all validations pass, return true
      return true;
    },
    searchQuery() {
      const queryParts = [];

      // Helper function to add non-empty strings to the query
      const addToQuery = (value) => {
        if (value) {
          // Split by space and add each part
          queryParts.push(...value.split(" ").filter((part) => part.trim() !== ""));
        }
      };

      // Add each field to the query if they exist
      addToQuery(this.manufacturer);
      addToQuery(this.model);
      addToQuery(this.registration);
      addToQuery(this.serialNumber);

      // Remove duplicates
      return Array.from(new Set(queryParts));
    },
  },
  watch: {
    listingId(newVal) {
      if (newVal) {
        this.loadListingData();
      }
    },
    avionics: {
      handler(newVal) {
        const processedAvionics = this.processArrayItems(newVal, "firstLetterEachWord");
        if (JSON.stringify(this.avionics) !== JSON.stringify(processedAvionics)) {
          this.avionics = processedAvionics;
        }
      },
      deep: true,
    },
    equipment: {
      handler(newVal) {
        const processedEquipment = this.processArrayItems(newVal, "firstLetterEachWord");
        if (JSON.stringify(this.equipment) !== JSON.stringify(processedEquipment)) {
          this.equipment = processedEquipment;
        }
      },
      deep: true,
    },
    // Whenever contactPhone changes, validate both fields.
    contactPhone(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$refs.phoneField.validate();
        this.$refs.emailField.validate();
      }
    },
    // Whenever contactEmail changes, validate both fields.
    contactEmail(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$refs.phoneField.validate();
        this.$refs.emailField.validate();
      }
    },
  },
  methods: {
    async loadListingData() {
      if (!this.listingId) {
        // Handle error if listing ID is not set
        this.$emit("loadListingError");
        this.$emit("closeEditDueToError");
        return;
      }

      try {
        const docRef = doc(db, "listings", this.listingId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          // Assign the data to your component's data properties
          this.manufacturer = data.manufacturer;
          this.year = data.year;
          this.model = data.model;
          this.price = data.price;
          this.highlight = data.highlight;
          this.registration = data.registration;
          this.serialNumber = data.serialNumber;
          this.base = data.base;
          this.totalTime = data.totalTime;
          this.engineTime = data.engineTime;
          this.secondEngineTime = data.secondEngineTime;
          this.propTime = data.propTime;
          this.secondPropTime = data.secondPropTime;
          this.aircraftCategory = data.aircraftCategory;
          this.aircraftPowerplant = data.aircraftPowerplant;
          this.aircraftClass = data.aircraftClass;
          this.description = data.description;
          this.avionics = data.avionics;
          this.equipment = data.equipment;
          this.contactName = data.contactName;
          this.contactPhone = data.contactPhone;
          this.contactEmail = data.contactEmail;
          this.engineOverhauled = data.engineOverhauled;
          this.secondEngineOverhauled = data.secondEngineOverhauled;
          this.engineTimeSinceOverhaul = data.engineTimeSinceOverhaul;
          this.secondEngineTimeSinceOverhaul = data.secondEngineTimeSinceOverhaul;
          this.propOverhauled = data.propOverhauled;
          this.secondPropOverhauled = data.secondPropOverhauled;
          this.propTimeSinceOverhaul = data.propTimeSinceOverhaul;
          this.secondPropTimeSinceOverhaul = data.secondPropTimeSinceOverhaul;
          this.images = data.images || [];
        } else {
          // Close the overlay if the listing doesn't exist
          this.$emit("loadListingError");
          this.$emit("closeEditDueToError");
        }
      } catch (error) {
        // Handle any other errors that might occur
        this.$emit("loadListingError", error.message);
        this.$emit("closeEditDueToError");
      }
    },
    close() {
      this.$emit("cancelEdit");
      this.manufacturer = null;
      this.year = null;
      this.model = null;
      this.price = null;
      this.highlight = null;
      this.registration = null;
      this.serialNumber = "";
      this.base = null;
      this.totalTime = null;
      this.engineTime = null;
      this.engineOverhauled = false;
      this.secondEngineTime = null;
      this.secondEngineOverhauled = false;
      this.propTime = null;
      this.propOverhauled = false;
      this.secondPropTime = null;
      this.secondPropOverhauled = false;
      this.aircraftCategory = null;
      this.aircraftClass = null;
      this.aircraftPowerplant = null;
      this.description = null;
      this.avionics = [];
      this.equipment = [];
      this.contactName = null;
      this.contactPhone = null;
      this.contactEmail = null;
      this.selectedImages = [];
      this.images = [];
      this.engineTimeSinceOverhaul = null;
      this.secondEngineTimeSinceOverhaul = null;
      this.propTimeSinceOverhaul = null;
      this.secondPropTimeSinceOverhaul = null;
    },
    async submitForm() {
      if (this.isFormValid) {
        try {
          this.isSubmitting = true;
          // Perform the update first
          await this.updateListing();

          // Now handle deletions concurrently
          const deletionPromises = this.imagesToDelete.map(async (imageUrl) => {
            const imageRef = ref(storage, imageUrl);
            try {
              await deleteObject(imageRef);
            } catch (error) {
              // Silently handle error
              // TODO: provide feedback to the user via snackbar
            }
          });

          await Promise.all(deletionPromises);

          this.isSubmitting = false;

          // Clear the imagesToDelete array after successful deletions
          this.imagesToDelete = [];

          // Emit an event to indicate the listing has been updated
          this.$emit("editSuccess");

          // Clear the form
          this.close();
        } catch (error) {
          this.isSubmitting = false;
        }
      } else {
        // Silently handle error
        // TODO: provide feedback to the user via snackbar
      }
    },
    async updateImages(listingId) {
      const existingImageUrls = this.images.filter((image) => typeof image === "string");
      const newImages = this.images.filter((image) => image instanceof File);

      const uploadPromises = newImages.map(async (image) => {
        // Generate a random file name
        const extension = image.name.split(".").pop(); // Get the file extension
        const randomFileName = `${new Date().getTime()}-${Math.random()
          .toString(36)
          .substr(2, 9)}.${extension}`;
        const filePath = `listings/${listingId}/${randomFileName}`;
        const storageRef = ref(storage, filePath);

        try {
          const uploadResult = await uploadBytes(storageRef, image);
          return await getDownloadURL(uploadResult.ref);
        } catch (uploadError) {
          // Silently handle error
          // TODO: provide feedback to the user via snackbar
          return null;
        }
      });

      try {
        // Wait for all uploads to complete and concatenate with existing URLs
        const newImageUrls = (await Promise.all(uploadPromises)).filter((url) => url);
        const imageUrls = existingImageUrls.concat(newImageUrls);

        // Update the Firestore document
        const docRef = doc(db, "listings", listingId);
        await updateDoc(docRef, { images: imageUrls });
      } catch (updateError) {
        // Silently handle error
        // TODO: provide feedback to the user via snackbar
      }
    },
    async updateListing() {
      if (this.isFormValid) {
        try {
          const docRef = doc(db, "listings", this.listingId);
          await updateDoc(docRef, this.saveData());
          // Update images if necessary
          await this.updateImages(docRef.id);

          this.$emit("listingUpdated");
        } catch (error) {
          this.$emit("listingError");
        }
      }
    },
    saveData() {
      // Create an object with the current state of the form fields
      const dataToSave = {
        // Assuming that getStringValue is a method that handles null or undefined values
        manufacturer: this.getStringValue(this.manufacturer),
        year: this.getStringValue(this.year),
        model: this.getStringValue(this.model),
        price: this.getStringValue(this.price),
        highlight: this.getStringValue(this.highlight),
        registration: this.getStringValue(this.registration),
        serialNumber: this.getStringValue(this.serialNumber),
        base: this.getStringValue(this.base),
        totalTime: this.getStringValue(this.totalTime),
        engineTime: this.getStringValue(this.engineTime),
        secondEngineTime: this.getStringValue(this.secondEngineTime),
        propTime: this.getStringValue(this.propTime),
        secondPropTime: this.getStringValue(this.secondPropTime),
        aircraftPowerplant: this.getStringValue(this.aircraftPowerplant),
        aircraftClass: this.getStringValue(this.aircraftClass),
        aircraftCategory: this.getStringValue(this.aircraftCategory),
        description: this.getStringValue(this.description),
        avionics: this.avionics, // Assuming avionics is already an array of strings
        equipment: this.equipment, // Assuming equipment is already an array of strings
        contactName: this.getStringValue(this.contactName),
        contactPhone: this.getStringValue(this.contactPhone),
        contactEmail: this.getStringValue(this.contactEmail),
        engineOverhauled: this.engineOverhauled,
        secondEngineOverhauled: this.secondEngineOverhauled,
        engineTimeSinceOverhaul: this.getStringValue(this.engineTimeSinceOverhaul),
        secondEngineTimeSinceOverhaul: this.getStringValue(this.secondEngineTimeSinceOverhaul),
        propOverhauled: this.propOverhauled,
        secondPropOverhauled: this.secondPropOverhauled,
        propTimeSinceOverhaul: this.getStringValue(this.propTimeSinceOverhaul),
        secondPropTimeSinceOverhaul: this.getStringValue(this.secondPropTimeSinceOverhaul),
        searchQuery: this.searchQuery,
      };
      return dataToSave;
    },
    capitalizeText(input, type) {
      if (!input) return null;

      switch (type) {
        case "firstLetterEachWord":
          return input.replace(/\w\S*/g, (word) => word.charAt(0).toUpperCase() + word.substr(1));
        case "allUpper":
          return input.toUpperCase();
        default:
          return input;
      }
    },

    // Method to handle the blur event for various fields
    handleBlur(field) {
      let type = "firstLetterEachWord"; // Default capitalization type

      // Define different capitalization types for specific fields
      if (["serialNumber", "registration", "base"].includes(field)) {
        type = "allUpper";
      }

      this[field] = this.capitalizeText(this[field], type);
    },
    processArrayItems(items, capitalizationType) {
      return items.map((item) => this.capitalizeText(item, capitalizationType));
    },
    formatPhoneNumber() {
      if (!this.contactPhone) {
        return; // Exit if contactPhone is null or undefined
      }

      const numbers = this.contactPhone.replace(/\D/g, ""); // Remove non-digits
      const char = { 0: "(", 3: ") ", 6: "-" };
      this.contactPhone = "";

      numbers.split("").forEach((num, i) => {
        this.contactPhone += (char[i] || "") + num;
      });
    },
    addImages() {
      this.images.push(...this.selectedImages);
      this.selectedImages = [];
    },
    removeImage(imageUrl) {
      // Add the URL to the imagesToDelete array
      this.imagesToDelete.push(imageUrl);

      // Remove the image from the images array
      const index = this.images.indexOf(imageUrl);
      if (index > -1) {
        this.images.splice(index, 1);
      }
    },
    getStringValue(value) {
      return value || ""; // If value is null, return an empty string
    },
    getObjectURL(image) {
      // If image is already a URL string, return it as is
      if (typeof image === "string") {
        return image;
      }
      // If image is a File object, create a URL
      if (image instanceof File) {
        return URL.createObjectURL(image);
      }
      // Otherwise, return an empty string
      return "";
    },
  },
};
</script>

<style scoped>
.overlay-card {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.custom-title {
  font-weight: bold;
  color: #0070ba;
}

.custom-subheader-wrapper {
  font-weight: bold;
  margin-bottom: 16px;
}

.custom-list-subheader {
  background-color: #f5f5f5;
  padding: 12px 16px;
  font-size: 14px;
  letter-spacing: 0.3px;
  color: #636363;
  font-weight: 500;
}

.scrollable-card-text {
  max-height: 90vh;
  overflow-y: auto;
}

.image-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.image-container {
  overflow: hidden; /* Ensures the image doesn't overflow if there's any misalignment */
  border-radius: 4px; /* Rounded corners for the thumbnails */
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.relative-position {
  position: relative;
  z-index: 0; /* Ensure a new stacking context. */
}

.position-absolute {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1; /* Ensure it's above the image container. */
}

.adjusted-icon {
  margin-top: -4px; /* Adjust this value to move the icon up more or less */
  margin-right: -4px; /* Adjust this value to move the icon right more or less */
}

.scrollable-card-text::-webkit-scrollbar {
  width: 10px;
}

.scrollable-card-text::-webkit-scrollbar-thumb {
  background-color: rgba(207, 207, 207, 0.8);
  border-radius: 5px;
}

.scrollable-card-text::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

@media (max-width: 959px) {
  .custom-btn-container {
    margin-left: 12px;
  }
  .custom-btn {
    width: 70%; /* Make the button stretch */
  }
}
</style>
