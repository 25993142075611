<!-- src/components/CategoryDropdown.vue -->

<template>
  <v-menu :close-on-content-click="false">
    <template v-slot:activator="{ props }">
      <v-btn color="primary" variant="outlined" rounded="xs" v-bind="props" class="ml-2 mr-2">
        Category
        <v-icon end>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-item-group
        v-if="categoryOptions.length"
        multiple
        v-model="selectedItems"
        @input="updateFilter"
      >
        <v-list-item v-for="(category, index) in categoryOptions" :key="index">
          <v-checkbox-btn
            color="primary"
            :label="category"
            v-model="selectedItems[index]"
          ></v-checkbox-btn>
        </v-list-item>
      </v-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "CategoryDropdown",
  computed: {
    ...mapState(["filters"]),
  },
  watch: {
    filters: {
      deep: true,
      handler(newFilters) {
        if (!newFilters.category) {
          this.selectedItems = [];
        }
      },
    },
  },
  data() {
    return {
      selectedItems: [],
      categoryOptions: ["Airplane", "Helicopter"],
    };
  },
  methods: {
    ...mapActions(["setFilter"]),
    updateFilter() {
      const selectedCategory = this.categoryOptions.filter((_, index) => this.selectedItems[index]);
      this.setFilter({ filterType: "category", value: selectedCategory });
    },
  },
};
</script>
