// src/main.js

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "font-awesome/css/font-awesome.css";
import vuetify from "./plugins/vuetify";

createApp(App).use(store).use(router).use(vuetify).mount("#app");

// Dispatch checkAuth action after the app is mounted
store.dispatch("checkAuth");
